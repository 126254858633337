import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Button, Input, Textarea } from 'oxyrion-ui/lib/';
import EmailEditor from 'react-email-editor';
import defaultDesign from './defaultHTMLDesign.json';
import { __ } from '../../Utils';
import API2 from '../../API2';
import PopUpWrapper from '../PopUpWrapper';

const ButtonWrapper = styled.div`
  display: flex;
  /* flex-direction: table-column;
  flex-direction: column; */
  justify-content: flex-end;
  /* margin-top: ${rem(32)}; */
  /* position: absolute; */
  /* right: ${rem(72)};  */
  /* bottom: ${rem(150)}; */
  /* width: ${rem(352)}; */
  background-color: rgb(249, 249, 249);
`;

const SaveButton = styled(Button)`
  margin: ${rem(10)};
  padding: ${rem(10)};
`;

const FinalSaveButton = styled(SaveButton)`
  color: white;
`;

const Container = styled.div`
  position: relative;
  padding-bottom: ${rem(24)};
  margin-top: -1px;
  border-top: 1px solid orange;
`;

const PopupContentWrapper = styled.div`
  padding: ${rem(16)};

  .item {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: ${rem(16)};
  }

  .title {
    display: flex;
    margin-bottom: ${rem(4)};
    font-size: ${rem(14)};
    font-weight: bold;
  }

  .confirm-wrapper {
    display: flex;
    justify-content: flex-end;
  }
`;

class NewsletterEditor extends React.Component {
  constructor(props) {
    super(props);
    this.editorRef = null;
    this.isEditorLoaded = false;
    this.isComponentMounted = false;
  }

  state = {
    loadingAIContent: false,
    showGenerateAIDialog: false,
    aiTitle: '',
    aiText: '',
  };

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.keyProps !== nextProps.keyProps) {
      this.isEditorLoaded = true;
      this.isComponentMounted = true;
      this.loadDesign();
    }
    if (this.props.translatedHtmlData !== nextProps.translatedHtmlData) {
      this.translateNewsletter(nextProps.translatedHtmlData);
    }
  }

  onLoad = () => {
    this.editorRef = this.editor.editor;
    this.isEditorLoaded = true;
    this.loadDesign();
  };

  onSave = () => {
    this.editorRef.exportHtml((data) => {
      this.props.onSave({ json_data: data.design, html_data: data.html });
    });
  };

  generateAIContent = async () => {
    this.setState({
      loadingAIContent: true,
    });

    try {
      const result = await API2.getAIGeneratedBlogContent(this.props.firm, {
        body: {
          title: this.state.aiTitle,
          text: this.state.aiText,
          lang: this.props.selectedLang,
        },
      });

      const designData = defaultDesign;

      designData.body.rows[0].columns[0].contents[0].values.html =
        result.htmlData;
      this.editorRef.loadDesign(designData);
      this.props.setEditor(this.editorRef);

      this.setState({
        loadingAIContent: false,
        showGenerateAIDialog: false,
      });
    } catch (e) {
      this.setState({
        loadingAIContent: false,
      });
    }
  };

  translateNewsletter = async (htmlData) => {
    try {
      const designData = defaultDesign;

      designData.body.rows[0].columns[0].contents[0].values.html = htmlData;
      this.editorRef.loadDesign(designData);
      this.props.setEditor(this.editorRef);
    } catch (e) {
      console.log(e);
    }
  };

  loadDesign = () => {
    const { jsonData, htmlData } = this.props;

    setTimeout(() => {
      if (!this.isEditorLoaded || !this.isComponentMounted) {
        this.loadDesign();
      } else {
        if (jsonData && jsonData.length) {
          this.editorRef.loadDesign(JSON.parse(jsonData));
        } else {
          const designData = defaultDesign;
          if (htmlData && htmlData.length) {
            designData.body.rows[0].columns[0].contents[0].values.html =
              htmlData;
          }
          this.editorRef.loadDesign(designData);
        }

        this.props.setEditor(this.editorRef);
      }
    }, 100);
  };

  render() {
    const { aiText, aiTitle } = this.state;

    return (
      <Container>
        <PopUpWrapper
          display={this.state.showGenerateAIDialog}
          small
          message={__('Zadajte požiadavku pre AI')}
          onClose={() =>
            this.setState({
              showGenerateAIDialog: false,
            })
          }
        >
          <PopupContentWrapper>
            <div className="item">
              <div className="title">{__('Názov článku')}</div>
              <div className="input">
                <Input
                  placeholder={__('Zadajte názov článku')}
                  value={aiTitle}
                  onChange={(e) => {
                    this.setState({
                      aiTitle: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="item">
              <div className="title">{__('Stručné inštrukcie pre AI')}</div>
              <div className="input">
                <Textarea
                  placeholder={__('Zadajte názov článku')}
                  value={aiText}
                  onChange={(e) => {
                    this.setState({
                      aiText: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="confirm-wrapper">
              <FinalSaveButton
                loading={this.state.loadingAIContent}
                small
                primary
                disabled={!(aiText.length > 0 && aiTitle.length > 5)}
                onClick={this.generateAIContent}
              >
                {__('Generovať článok pomocou AI')}
              </FinalSaveButton>
            </div>
          </PopupContentWrapper>
        </PopUpWrapper>
        <ButtonWrapper>
          <FinalSaveButton small primary onClick={this.onSave}>
            {__('Uložiť')}
          </FinalSaveButton>

          <FinalSaveButton
            loading={this.state.loadingAIContent}
            small
            primary
            onClick={() =>
              this.setState({
                showGenerateAIDialog: true,
              })
            }
          >
            {__('Generovať článok pomocou AI')}
          </FinalSaveButton>
        </ButtonWrapper>
        <EmailEditor
          ref={(editor) => {
            this.editor = editor;
          }}
          key={this.props.keyProps}
          onLoad={this.onLoad}
          minHeight={rem(window.innerHeight - 170)}
        />
      </Container>
    );
  }
}

NewsletterEditor.propTypes = {
  selectedLang: PropTypes.string,
  keyProps: PropTypes.string,
  setEditor: PropTypes.func,
  jsonData: PropTypes.string,
  htmlData: PropTypes.string,
  onSave: PropTypes.func,
  translatedHtmlData: PropTypes.string,
};

NewsletterEditor.defaultProps = {
  selectedLang: 'SK',
  keyProps: '',
  setEditor: () => {},
  jsonData: '',
  htmlData: '',
  onSave: () => {},
  translatedHtmlData: '',
};

export default NewsletterEditor;
