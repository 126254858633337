import { __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    clickable: false,
  },
  {
    name: __('Typ odmeny'),
    clickable: false,
  },
  {
    name: __('Krajina'),
    clickable: false,
  },
  {
    name: __('Publikované'),
    clickable: false,
  },
  {
    name: __('Platí od'),
    clickable: false,
  },
  {
    name: __('Platí do'),
    clickable: false,
  },
  {
    name: __('Dátum úpravy'),
    clickable: false,
  },
  {
    name: __('Dátum vytvorenia'),
    clickable: false,
  },
];
