import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'oxyrion-ui/lib/Button';
import Icon from 'oxyrion-ui/lib/Icon';
import { Tr, Td } from 'oxyrion-ui/lib/Table';
import styled from 'styled-components';
import { rem } from 'polished';
import Input from 'oxyrion-ui/lib/Input';
import { __ } from '../../Utils';
import AdminAPI from '../../AdminAPI';
import SearchableSelect from '../SearchableSelect';

const ValueInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled(Input)`
  max-width: ${rem(95)};
  margin-right: ${rem(10)};
`;

const DesktopButton = styled(Button)`
  display: none;
  ${({ theme }) => theme.media.m`
  display: flex;
`};
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  display: flex
    ${({ theme }) => theme.media.m`
  display: none;
`};
`;

class AddNewLoyaltyProgramTier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      threshold_from: '',
      product: '',
    };
  }

  componentDidUpdate(oldProps) {
    if (oldProps.over !== this.props.over) {
      this.setState({ to: this.props.over });
    }
  }

  handleOnChange(field, value) {
    this.setState({ [field]: value });
  }

  fetchProducts(query) {
    try {
      return AdminAPI.getCatalogProductsVariantsAction({
        q: query || '',
        limit: 100,
        allCountries: true,
      }).then((res) => {
        const variants = res.variants.map((item) => ({
          value: item._id,
          label: item.name,
          product: item,
        }));
        this.setState({ variants });
        return variants;
      });
    } catch (e) {
      return [];
    }
  }

  checkData() {
    const { name, threshold_from, product } = this.state;

    return name !== '' && threshold_from !== '' && product !== '';
  }

  submit() {
    const { onSubmit } = this.props;
    const { name, threshold_from, product } = this.state;

    if (this.checkData()) {
      onSubmit(name, threshold_from, product);
      this.setState({
        name: '',
        threshold_from: '',
        product: '',
      });
    }
  }

  render() {
    const { name, threshold_from, product } = this.state;

    return (
      <Tr>
        <Td>
          <ValueInputWrapper>
            <StyledInput
              width="100%"
              type="text"
              //error={fromError}
              value={name}
              placeholder={__('Názov úrovne')}
              onChange={(e) => {
                this.handleOnChange('name', e.target.value);
              }}
            />
          </ValueInputWrapper>
        </Td>
        <Td>
          <ValueInputWrapper>
            <StyledInput
              width="100%"
              type="number"
              //error={fromError}
              value={threshold_from}
              placeholder={__('Obrat od')}
              onChange={(e) => {
                this.handleOnChange('threshold_from', e.target.value);
              }}
            />
          </ValueInputWrapper>
        </Td>
        <Td>
          <ValueInputWrapper>
            <SearchableSelect
              width="100%"
              value={product}
              loadOptions={(query) => this.fetchProducts(query)}
              placeholder={__('Vyhľadať produkt')}
              name={null}
              handleOnChange={(e) => this.handleOnChange('product', e)}
            />
          </ValueInputWrapper>
        </Td>
        <Td>
          <DesktopButton
            primary
            small
            disabled={name === '' || threshold_from === '' || product === ''}
            loading={this.props.buttonLoading}
            onClick={() => this.submit()}
          >
            {__('Pridať')}
          </DesktopButton>
          <StyledIcon
            name="plus"
            onClick={() => this.submit()}
            color="#ee6500"
          />
        </Td>
      </Tr>
    );
  }
}

AddNewLoyaltyProgramTier.propTypes = {
  buttonLoading: PropTypes.bool,
};

AddNewLoyaltyProgramTier.defaultProps = {
  buttonLoading: false,
};

export default AddNewLoyaltyProgramTier;
