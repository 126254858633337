import React from 'react';
import BaseTable from '../BaseTable';
import AdminAPI from '../../AdminAPI';
import tableHeaders from '../../ContentConfig/news';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import {
  __,
  renderLink,
  translate,
  formatDate,
  getPageIdFromProps,
} from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import ControlButton from '../../Components/ReusableComponents/ControlButton';
import { RouteLinkWraper as LinkWrap } from '../../Components/ReusableComponents';

class NewsListBase extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'priority' });
    this.headers = tableHeaders;
    this.segmentName = __('Novinky');
    this.accessors = [
      'name',
      'perex',
      'published',
      'published_from',
      'published_to',
      'created_date',
      'updated_date',
      'priority',
    ];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.setSorter('priority');
    this.state = {
      ...this.state,
      lastSearch: '',
      activeMessage: 'Všetky',
    };

    this.pageId = getPageIdFromProps(this.props) || this.props.type;
  }

  componentDidUpdate() {
    if (this.pageId !== getPageIdFromProps(this.props)) {
      this.pageId = getPageIdFromProps(this.props);
      this.init();
    }
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, active, lastSearch, activeMessage } = this.state;
      this.setState({ loading: true });
      let params = {};

      if (active === null) {
        params = Object.assign({}, parameters, { sorter });
      } else {
        params = Object.assign({}, parameters, { sorter, active });
      }
      if (!params.q) {
        params.q = lastSearch;
      }

      if (activeMessage === 'Všetky') {
        params.filter = 'all';
      } else if (activeMessage === 'Publikované - SK') {
        params.filter = 'published_sk';
      } else if (activeMessage === 'Publikované - CZ') {
        params.filter = 'published_cz';
      } else if (activeMessage === 'Publikované - EN') {
        params.filter = 'published_en';
      } else if (activeMessage === 'Publikované - AT') {
        params.filter = 'published_at';
      } else if (activeMessage === 'Aktuálne zobrazené pre CZ') {
        params.filter = 'live_cz';
      } else if (activeMessage === 'Aktuálne zobrazené pre EN') {
        params.filter = 'live_en';
      } else if (activeMessage === 'Aktuálne zobrazené pre AT') {
        params.filter = 'live_at';
      }
      params.articleType = this.pageId;
      const newData = await AdminAPI.getNewsListAction(params);
      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  handleNewsOnClick(id) {
    const { history, firm } = this.props;

    history.push(`/${firm}/news/${this.pageId}/${id}`);
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map((header) => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        name: _shapeData(
          renderLink(
            `./${this.pageId}/${item._id}`,
            `${
              translate(
                this.props.firm,
                item.name && item.name.translations && item.name.translations,
                item.name && item.name.value && item.name.value,
              ) || '-'
            }`,
          ),
          () => this.handleNewsOnClick(item._id),
        ),
        perex: _shapeData(
          translate(
            this.props.firm,
            item.name && item.name.translations && item.name.translations,
            item.name && item.name.value && item.name.value,
          ) || '-',
        ),
        published: _shapeData(
          item.published && item.published.length <= 0
            ? __('Nepublikované')
            : item.published && item.published.join(),
        ),
        published_from: item.published_from
          ? _shapeData(formatDate(item.published_from))
          : _shapeData(__('N/A')),
        published_to: item.published_to
          ? _shapeData(formatDate(item.published_to))
          : _shapeData(__('N/A')),
        created_date: _shapeData(formatDate(item.created_date)),
        updated_date: _shapeData(formatDate(item.updated_date)),
        priority: _shapeData(item.priority),
      });
      return acumulator;
    }, []);
    return result;
  }

  handleTypeChange(status, activeMessage) {
    this.saveFiltersToLocalStorage(this.state.selectedFilter);
    this.setState(
      { active: status, sorter: 'priority', activeMessage: __(activeMessage) },
      () => {
        this.setSorter('priority');
        this.fetchData();
      },
    );
  }

  getTitle(type) {
    switch (type) {
      case 'news':
        return __('Novinky - Portál');
      case 'blogs':
        return __('Blogy - Eshop');
      case 'news-dejmark':
        return __('Novinky - Dejmark');
      default:
        return __('Novinky - Portál');
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const { activeMessage } = this.state;

    return (
      <ControllBar
        history={history}
        name={this.getTitle(this.pageId)}
        onChange={(val) => this.loadSearchRrsult(val)}
      >
        <ControlButton name={activeMessage}>
          <LinkWrap>
            <div
              onClick={(e) => this.handleTypeChange(true, e.target.innerHTML)}
            >
              {__('Všetky')}
            </div>
            <div
              onClick={(e) => this.handleTypeChange(true, e.target.innerHTML)}
            >
              {__('Publikované - SK')}
            </div>
            <div
              onClick={(e) => this.handleTypeChange(false, e.target.innerHTML)}
            >
              {__('Publikované - CZ')}
            </div>
            <div
              onClick={(e) => this.handleTypeChange(false, e.target.innerHTML)}
            >
              {__('Publikované - EN')}
            </div>
            <div
              onClick={(e) => this.handleTypeChange(false, e.target.innerHTML)}
            >
              {__('Publikované - AT')}
            </div>
            <div
              onClick={(e) => this.handleTypeChange(false, e.target.innerHTML)}
            >
              {__('Aktuálne zobrazené pre SK')}
            </div>
            <div
              onClick={(e) => this.handleTypeChange(false, e.target.innerHTML)}
            >
              {__('Aktuálne zobrazené pre CZ')}
            </div>
            <div
              onClick={(e) => this.handleTypeChange(false, e.target.innerHTML)}
            >
              {__('Aktuálne zobrazené pre EN')}
            </div>
            <div
              onClick={(e) => this.handleTypeChange(false, e.target.innerHTML)}
            >
              {__('Aktuálne zobrazené pre AT')}
            </div>
          </LinkWrap>
        </ControlButton>
        <ControlBarButton
          primary
          small
          onClick={() => this.handleNewsOnClick('new')}
          icon="plus"
        >
          {__('Vytvoriť článok')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(NewsListBase);
