import React from 'react';
import moment from 'moment';
import BaseTable from '../BaseTable';
import tableHeaders from '../../ContentConfig/loyaltyPrograms';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import {
  __,
  renderLink,
  formatDate,
  FORMAT_HUMANE_DATETIME,
} from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import AdminAPI from '../../AdminAPI';
import { Icon } from 'oxyrion-ui/lib';

class LoyaltyPrograms extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: '-created_date' });
    this.headers = tableHeaders;
    this.segmentName = __('Vernostné programy');
    this.showBulkOperations = false;
    this.accessors = [
      'name',
      'reward_type',
      'firm',
      'published',
      'valid_from',
      'valid_to',
      'updated_date',
      'created_date',
    ];
    this.setSorter('-created_date');
    this.state = {
      ...this.state,
      selectedCustomer: null,
      selectedState: { label: __('Všetky stavy'), value: null },
      downloadSelectedLoading: false,
      commonMessage: { label: __('Všetky'), value: null },
      lastSearch: '',
    };
  }

  handleSystemOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/loyalty-program/${id}`);
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, active } = this.state;
      this.setState({ loading: true });
      let params;

      if (active === null) {
        params = Object.assign({}, parameters, { sorter });
      } else {
        params = Object.assign({}, parameters, { sorter, active });
      }

      const newData = await AdminAPI.getLoyaltyProgramsAction(
        this.props.firm,
        params,
      );
      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        id: item._id,
        name: _shapeData(
          renderLink(`./loyalty-program/${item._id}`, `${item.name || '-'}`),
          () => this.handleSystemOnClick(item._id),
        ),
        reward_type: _shapeData(item.reward_type),
        firm: _shapeData(item.firm),
        published: _shapeData(
          item.published ? (
            <Icon size="m" name="check" />
          ) : (
            <Icon size="m" name="close" color={'#ee6500'} />
          ),
        ),
        valid_from: _shapeData(
          formatDate(moment(item.valid_from), FORMAT_HUMANE_DATETIME),
        ),
        valid_to: _shapeData(
          formatDate(moment(item.valid_to), FORMAT_HUMANE_DATETIME),
        ),
        updated_date: _shapeData(
          formatDate(moment(item.updated_date), FORMAT_HUMANE_DATETIME),
        ),
        created_date: _shapeData(
          formatDate(moment(item.created_date), FORMAT_HUMANE_DATETIME),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const { history } = this.props;

    return (
      <ControllBar history={history} name={__('Vernostné programy')}>
        <ControlBarButton
          primary
          small
          onClick={() => this.handleSystemOnClick('new')}
          icon="plus"
        >
          {__('Pridať vernostný program')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(LoyaltyPrograms);
