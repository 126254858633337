/* eslint-disable */
import 'isomorphic-fetch';

class Api {
  constructor() {
    this.baseUrl = null;
    this.token = null;
    this.headers = [];
  }

  serializeQueryParams(parameters) {
    return Object.keys(parameters)
      .reduce((acc, p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  paramsToObject(params) {
    let query = params.substr(1);
    let result = {};
    query.split('&').forEach(function (part) {
      let item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  mergeQueryParams(parameters, queryParameters) {
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (
        parameterName,
      ) {
        const parameter = parameters.$queryParameters[parameterName];
        queryParameters[parameterName] = parameter;
      });
    }
    return queryParameters;
  }

  transformParameter(value, transformOperation = null) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  /**
   * HTTP Request
   * @method
   * @param {string} method - http method
   * @param {string} url - url to do request
   * @param {object} body - body parameters / object
   * @param {object} headers - header parameters
   * @param {object} queryParameters - querystring parameters
   */
  request(
    method,
    url,
    body,
    headers,
    queryParameters,
    form,
    checkFor401 = true,
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    // ugly hack, we need to delete Content-Type header with multipart/form-data
    // that way, browser will calculate form specific headers on it's own
    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (body && !Object.keys(body).length) {
      body = undefined;
    } else {
      body = JSON.stringify(body);
    }

    if (form && Object.keys(form).length) {
      body = new FormData();
      for (let k in form) {
        body.append(k, form[k]);
      }
    }

    if (this.headers.length > 0) {
      this.headers.forEach((h) => {
        headers.append(h.name, h.value);
      });
    }

    return fetch(urlWithParams, {
      method,
      headers,
      body,
    })
      .then((response) => {
        if (checkFor401) {
          if (response.status === 401) {
            if (typeof this._onResponseUnauthorized === 'function') {
              this._onResponseUnauthorized();
            } else {
              let error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
          }
        }

        if (response.status === 204) return response;

        if (response.ok) {
          const responseContentType =
            (response.headers && response.headers.get('Content-Type')) || '';
          if (responseContentType.includes('application/json')) {
            return response.json();
          }
          return response;
        } else {
          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .catch((error) => {
        return error.response.json().then((error_details) => {
          error.details = error_details;
          throw error;
        });
      });
  }

  /**
   * Set base url
   * @method
   * @param {string} baseUrl
   */
  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }

  /**
   * Set Headers
   * @method
   * @param headers - array of headers
   */
  setHeaders(headers) {
    this.headers = headers;
  }

  set onResponseUnauthorized(callback) {
    this._onResponseUnauthorized = callback;
  }

  /**
   * Redirects a user to a given url
   * @method
   * @param {string} url
   */
  redirect(url) {
    window.location = url;
  }

  /**
   * Set Token
   * @method
   * @param {string} token - token's value
   */
  setToken(token) {
    this.token = token;
  }

  /**
   * Set Auth headers
   * @method
   * @param {object} headerParams - headers object
   */
  appendAuthHeaders(headerParams) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  /**
     * Get informations about user.

     * @method
     * @param {object} parameters - method options and parameters
     */
  meAction(parameters = {}) {
    let path = '/me';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get eshop Vat rate

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
     */
  getEshopVatAction(companyId, parameters = {}) {
    let path = '/{companyId}/vat-info';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get token info

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.accessToken - 
     */
  getTokenInfoAction(parameters = {}) {
    let path = '/token-info';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['accessToken'] !== 'undefined') {
      queryParameters['accessToken'] = parameters['accessToken'];
    }

    queryParameters['accessToken'] = this.transformParameter(
      queryParameters['accessToken'],
    );

    if (typeof parameters['accessToken'] === 'undefined') {
      throw Error('Missing required parameter: accessToken');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Authorize xColor user.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.state -
   * @param {} parameters.cartId -
   * @param {} parameters.body -
   */
  oauthAuthorizeXColorAction(parameters = {}) {
    let path = '/oauth/authorize/eshop';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Accept', 'text/html');
    headers.append('Accept', 'application/json');

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['state'] === 'undefined') {
      throw Error('Missing required parameter: state');
    }

    if (typeof parameters['cartId'] !== 'undefined') {
      queryParameters['cartId'] = parameters['cartId'];
    }

    queryParameters['cartId'] = this.transformParameter(
      queryParameters['cartId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Authorize xColor B2B user.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.state -
   * @param {} parameters.cartId -
   * @param {} parameters.body -
   */
  oauthAuthorizeXColorB2BAction(parameters = {}) {
    let path = '/oauth/authorize/eshop/b2b';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Accept', 'text/html');
    headers.append('Accept', 'application/json');

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['state'] === 'undefined') {
      throw Error('Missing required parameter: state');
    }

    if (typeof parameters['cartId'] !== 'undefined') {
      queryParameters['cartId'] = parameters['cartId'];
    }

    queryParameters['cartId'] = this.transformParameter(
      queryParameters['cartId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Refresh token

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postRefreshTokenAction(parameters = {}) {
    let path = '/oauth/refresh-token';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update user data.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  putUserAction(parameters = {}) {
    let path = '/update-user';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get user data.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getUserAction(parameters = {}) {
    let path = '/update-user';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Change  user's subscription from newsletter

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} token - Restore password token
       * @param {} parameters.body - 
     */
  putEshopUserNewsletterSubscriptionAction(token, parameters = {}) {
    let path = '/newsletter-subscription/{token}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{token}', token);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Change  user's unSubscription from newsletter

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} contactId - id of product
       * @param {} newsletterId - id of newsletter
     */
  putEshopUserNewsletterUnSubscriptionAction(
    contactId,
    newsletterId,
    parameters = {},
  ) {
    let path = '/newsletter-unsubscribe/{newsletterId}/{contactId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{contactId}', contactId);

    path = path.replace('{newsletterId}', newsletterId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Change password of logged user

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  changePasswordAction(parameters = {}) {
    let path = '/change-password';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get categories tree.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} pageId - 
       * @param {} parameters.showIndustryCategories - 
       * @param {} parameters.chanelId - 
       * @param {} parameters.lang - 
     */
  getCategoriesTreeAction(pageId, parameters = {}) {
    let path = '/{pageId}/categories-tree';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{pageId}', pageId);

    if (typeof parameters['showIndustryCategories'] !== 'undefined') {
      queryParameters['showIndustryCategories'] =
        parameters['showIndustryCategories'];
    }

    queryParameters['showIndustryCategories'] = this.transformParameter(
      queryParameters['showIndustryCategories'],
    );

    if (typeof parameters['chanelId'] !== 'undefined') {
      queryParameters['chanelId'] = parameters['chanelId'];
    }

    queryParameters['chanelId'] = this.transformParameter(
      queryParameters['chanelId'],
    );

    if (typeof parameters['chanelId'] === 'undefined') {
      throw Error('Missing required parameter: chanelId');
    }

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get favorite categories

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.chanelId - 
       * @param {} parameters.lang - 
     */
  getFavoriteCategoriesAction(parameters = {}) {
    let path = '/favorite-categories';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['chanelId'] !== 'undefined') {
      queryParameters['chanelId'] = parameters['chanelId'];
    }

    queryParameters['chanelId'] = this.transformParameter(
      queryParameters['chanelId'],
    );

    if (typeof parameters['chanelId'] === 'undefined') {
      throw Error('Missing required parameter: chanelId');
    }

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get color categories tree.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.productUrl - Url of product
     */
  getColorCategoriesAction(parameters = {}) {
    let path = '/color-categories';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['productUrl'] !== 'undefined') {
      queryParameters['productUrl'] = parameters['productUrl'];
    }

    queryParameters['productUrl'] = this.transformParameter(
      queryParameters['productUrl'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get icons.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getIconsAction(parameters = {}) {
    let path = '/icons';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get barcode image.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} contactId - id of product
     */
  getEANImageAction(contactId, parameters = {}) {
    let path = '/get-ean/{contactId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get inspirations.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} pageId - 
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.objects - Objects of inspirations
       * @param {} parameters.surfaces - Surfaces of inspirations
       * @param {} parameters.styles - Styles of inspirations
       * @param {} parameters.colors - Colors of inspirations
       * @param {} parameters.q - Qickseach by inspiration name
       * @param {} parameters.state - State
       * @param {} parameters.sorter - Sorter
     */
  getInspirationsAction(companyId, pageId, parameters = {}) {
    let path = '/{pageId}/{companyId}/inspirations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{pageId}', pageId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['objects'] !== 'undefined') {
      queryParameters['objects'] = parameters['objects'];
    }

    queryParameters['objects'] = this.transformParameter(
      queryParameters['objects'],
    );

    if (typeof parameters['surfaces'] !== 'undefined') {
      queryParameters['surfaces'] = parameters['surfaces'];
    }

    queryParameters['surfaces'] = this.transformParameter(
      queryParameters['surfaces'],
    );

    if (typeof parameters['styles'] !== 'undefined') {
      queryParameters['styles'] = parameters['styles'];
    }

    queryParameters['styles'] = this.transformParameter(
      queryParameters['styles'],
    );

    if (typeof parameters['colors'] !== 'undefined') {
      queryParameters['colors'] = parameters['colors'];
    }

    queryParameters['colors'] = this.transformParameter(
      queryParameters['colors'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    queryParameters['sorter'] = 'created_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get inspiration objects.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} brand - 
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
     */
  getInspirationsByBrandAction(companyId, brand, parameters = {}) {
    let path = '/{companyId}/inspirations-by-brand/{brand}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{brand}', brand);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get inspiration objects.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} pageId - 
       * @param {} companyId - 
       * @param {} brand - 
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} inspirationsObjectId - ID of inspiration
     */
  getInspirationsByBrandAndObjectAction(
    pageId,
    companyId,
    brand,
    inspirationsObjectId,
    parameters = {},
  ) {
    let path =
      '/{pageId}/{companyId}/inspirations-by-brand-and-object/{brand}/{inspirationsObjectId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{pageId}', pageId);

    path = path.replace('{companyId}', companyId);

    path = path.replace('{brand}', brand);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{inspirationsObjectId}', inspirationsObjectId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get inspiration objects.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} brand - 
     */
  getInspirationsObjectsByBrandAction(companyId, brand, parameters = {}) {
    let path = '/{companyId}/inspirations-objects-by-brand/{brand}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{brand}', brand);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get inspirations objects.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} pageId - 
     */
  getInspirationsObjectsAction(companyId, pageId, parameters = {}) {
    let path = '/{pageId}/{companyId}/inspirations-objects';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{pageId}', pageId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get inspirations object details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} pageId - 
       * @param {} companyId - 
       * @param {} inspirationsObjectId - ID of inspiration
     */
  getInspirationsObjectAction(
    pageId,
    companyId,
    inspirationsObjectId,
    parameters = {},
  ) {
    let path =
      '/{pageId}/{companyId}/inspirations-object/{inspirationsObjectId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{pageId}', pageId);

    path = path.replace('{companyId}', companyId);

    path = path.replace('{inspirationsObjectId}', inspirationsObjectId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get inspirations object details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} pageId - 
       * @param {} companyId - 
       * @param {} inspirationsObjectPath - path of inspiration
     */
  getInspirationsObjectByPathAction(
    pageId,
    companyId,
    inspirationsObjectPath,
    parameters = {},
  ) {
    let path =
      '/{pageId}/{companyId}/inspirations-object-by-path/{inspirationsObjectPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{pageId}', pageId);

    path = path.replace('{companyId}', companyId);

    path = path.replace('{inspirationsObjectPath}', inspirationsObjectPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get inspiration details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} pageId - 
       * @param {} inspirationId - ID of inspiration
     */
  getInspirationAction(companyId, pageId, inspirationId, parameters = {}) {
    let path = '/{pageId}/{companyId}/inspirations/{inspirationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{pageId}', pageId);

    path = path.replace('{inspirationId}', inspirationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get inspirations filters.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
     */
  getInspirationsFiltersAction(companyId, parameters = {}) {
    let path = '/{companyId}/inspirations-filters';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Qickseach by product name, ID, Code ...
       * @param {} parameters.priceFrom - Price from
       * @param {} parameters.tintingFilter - filter for tinted or not tinted products
       * @param {} parameters.priceTo - Price to
       * @param {} parameters.sorter - Sorter
       * @param {} parameters.useVat - Use price with VAT
       * @param {} parameters.categoryName - Category of publication name
       * @param {} parameters.q - Category of publication name
       * @param {} parameters.filters - Filters to be applied
       * @param {} parameters.tintingOnly - Filter for filter products for tinters
       * @param {} parameters.lang - Translate values to selected lang
       * @param {} parameters.onlyPurchased - Filter only purchased products
     */
  getProductsAction(companyId, parameters = {}) {
    let path = '/{companyId}/products';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['priceFrom'] !== 'undefined') {
      queryParameters['priceFrom'] = parameters['priceFrom'];
    }

    queryParameters['priceFrom'] = this.transformParameter(
      queryParameters['priceFrom'],
    );

    if (typeof parameters['tintingFilter'] !== 'undefined') {
      queryParameters['tintingFilter'] = parameters['tintingFilter'];
    }

    queryParameters['tintingFilter'] = this.transformParameter(
      queryParameters['tintingFilter'],
    );

    if (typeof parameters['priceTo'] !== 'undefined') {
      queryParameters['priceTo'] = parameters['priceTo'];
    }

    queryParameters['priceTo'] = this.transformParameter(
      queryParameters['priceTo'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['useVat'] !== 'undefined') {
      queryParameters['useVat'] = parameters['useVat'];
    }

    queryParameters['useVat'] = this.transformParameter(
      queryParameters['useVat'],
    );

    if (typeof parameters['categoryName'] !== 'undefined') {
      queryParameters['categoryName'] = parameters['categoryName'];
    }

    queryParameters['categoryName'] = this.transformParameter(
      queryParameters['categoryName'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    if (typeof parameters['tintingOnly'] !== 'undefined') {
      queryParameters['tintingOnly'] = parameters['tintingOnly'];
    }

    queryParameters['tintingOnly'] = this.transformParameter(
      queryParameters['tintingOnly'],
    );

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    if (typeof parameters['onlyPurchased'] !== 'undefined') {
      queryParameters['onlyPurchased'] = parameters['onlyPurchased'];
    }

    queryParameters['onlyPurchased'] = this.transformParameter(
      queryParameters['onlyPurchased'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get products items list

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Qickseach by product name, ID, Code ...
       * @param {} parameters.filters - Applicable filters
       * @param {} parameters.priceFrom - Price from
       * @param {} parameters.tintingFilter - filter for tinted or not tinted products
       * @param {} parameters.priceTo - Price to
       * @param {} parameters.sorter - Sorter
       * @param {} parameters.useVat - Use price with VAT
       * @param {} parameters.categoryName - Category of publication name
       * @param {} parameters.q - Category of publication name
       * @param {} parameters.filters - Filters to be applied
       * @param {} parameters.tintingOnly - Filter for filter products for tinters
       * @param {} parameters.lang - Translate values to selected lang
     */
  getProductsItemsAction(companyId, parameters = {}) {
    let path = '/{companyId}/products-items';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    if (typeof parameters['priceFrom'] !== 'undefined') {
      queryParameters['priceFrom'] = parameters['priceFrom'];
    }

    queryParameters['priceFrom'] = this.transformParameter(
      queryParameters['priceFrom'],
    );

    if (typeof parameters['tintingFilter'] !== 'undefined') {
      queryParameters['tintingFilter'] = parameters['tintingFilter'];
    }

    queryParameters['tintingFilter'] = this.transformParameter(
      queryParameters['tintingFilter'],
    );

    if (typeof parameters['priceTo'] !== 'undefined') {
      queryParameters['priceTo'] = parameters['priceTo'];
    }

    queryParameters['priceTo'] = this.transformParameter(
      queryParameters['priceTo'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['useVat'] !== 'undefined') {
      queryParameters['useVat'] = parameters['useVat'];
    }

    queryParameters['useVat'] = this.transformParameter(
      queryParameters['useVat'],
    );

    if (typeof parameters['categoryName'] !== 'undefined') {
      queryParameters['categoryName'] = parameters['categoryName'];
    }

    queryParameters['categoryName'] = this.transformParameter(
      queryParameters['categoryName'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    if (typeof parameters['tintingOnly'] !== 'undefined') {
      queryParameters['tintingOnly'] = parameters['tintingOnly'];
    }

    queryParameters['tintingOnly'] = this.transformParameter(
      queryParameters['tintingOnly'],
    );

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Search products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.q - Qickseach by product name, ID, Code ...
     */
  searchProductsAction(companyId, parameters = {}) {
    let path = '/{companyId}/products-search';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productUrl - Url of product
       * @param {} parameters.lang - lang of news articles
       * @param {} parameters.revalidateCache - 
     */
  getProductAction(companyId, productUrl, parameters = {}) {
    let path = '/{companyId}/products/{productUrl}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productUrl}', productUrl);

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    if (typeof parameters['revalidateCache'] !== 'undefined') {
      queryParameters['revalidateCache'] = parameters['revalidateCache'];
    }

    queryParameters['revalidateCache'] = this.transformParameter(
      queryParameters['revalidateCache'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product by ean.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} ean - Ean of product
       * @param {} parameters.lang - lang of news articles
     */
  getProductByEanAction(companyId, ean, parameters = {}) {
    let path = '/{companyId}/product-by-ean/{ean}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{ean}', ean);

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get list of product packages.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productUrl - Url of product
     */
  getProductPackagesAction(companyId, productUrl, parameters = {}) {
    let path = '/{companyId}/products/{productUrl}/product-packages';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productUrl}', productUrl);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productUrl - Url of product
       * @param {} parameters.lang - lang of news articles
     */
  getLiteProductAction(companyId, productUrl, parameters = {}) {
    let path = '/{companyId}/products-lite/{productUrl}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productUrl}', productUrl);

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product inspirations.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} productId - ID of product
       * @param {} parameters.q - Qickseach by inspiration name
       * @param {} parameters.state - State
       * @param {} parameters.sorter - Sorter
     */
  getProductInspirationsAction(companyId, productId, parameters = {}) {
    let path = '/{companyId}/products/{productId}/inspirations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{productId}', productId);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    queryParameters['sorter'] = '-created_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product variants.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productId - ID of product
       * @param {} parameters.sorter - Sorter
       * @param {} parameters.colorcardId - Colorcard ID
       * @param {} parameters.shadeId - Shade ID
       * @param {} parameters.colorCardPurpose - purpose of color card
     */
  getProductVariantsAction(companyId, productId, parameters = {}) {
    let path = '/{companyId}/products/{productId}/variants';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters['sorter'] = '-created_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['colorcardId'] !== 'undefined') {
      queryParameters['colorcardId'] = parameters['colorcardId'];
    }

    queryParameters['colorcardId'] = this.transformParameter(
      queryParameters['colorcardId'],
    );

    if (typeof parameters['shadeId'] !== 'undefined') {
      queryParameters['shadeId'] = parameters['shadeId'];
    }

    queryParameters['shadeId'] = this.transformParameter(
      queryParameters['shadeId'],
    );

    if (typeof parameters['colorCardPurpose'] !== 'undefined') {
      queryParameters['colorCardPurpose'] = parameters['colorCardPurpose'];
    }

    queryParameters['colorCardPurpose'] = this.transformParameter(
      queryParameters['colorCardPurpose'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product documentation.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productId - ID of product
     */
  getProductDocumentationAction(companyId, productId, parameters = {}) {
    let path = '/{companyId}/products/{productId}/documentation';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product by ean.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} ean - Ean of product
     */
  getScannedProductAction(companyId, ean, parameters = {}) {
    let path = '/{companyId}/scanned-product/{ean}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{ean}', ean);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get products keys in cache

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
     */
  getCachedProductsAction(companyId, parameters = {}) {
    let path = '/{companyId}/cache/products';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product detail from cache

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productUrl - Url of product
     */
  getCachedProductAction(companyId, productUrl, parameters = {}) {
    let path = '/{companyId}/cache/product/{productUrl}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productUrl}', productUrl);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update product detail in cache

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productId - ID of product
     */
  updateCachedProductAction(companyId, productId, parameters = {}) {
    let path = '/{companyId}/cache/product/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete product cache

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productId - ID of product
     */
  deleteCachedProductAction(companyId, productId, parameters = {}) {
    let path = '/{companyId}/cache/product/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get business info based on ICO
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} ico - ICO na vyhladanie
   * @param {} country - Country
   */
  getBusinessInfo(ico, country, parameters = {}) {
    let path = '/business-info/{ico}/{country}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{ico}', ico);

    path = path.replace('{country}', country);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get business info based on ICO
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} ico - ICO na vyhladanie
   * @param {} country - Country
   * @param {} parameters.body -
   */
  postFinstatDataAtRegistrationAction(ico, country, parameters = {}) {
    let path = '/business-info/{ico}/{country}/registration';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{ico}', ico);

    path = path.replace('{country}', country);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get customer delivery free info
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId -
   * @param {} parameters.customerType -
   */
  getFreeDeliveryPurchase(companyId, parameters = {}) {
    let path = '/{companyId}/customer/free-delivery';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['customerType'] !== 'undefined') {
      queryParameters['customerType'] = parameters['customerType'];
    }

    queryParameters['customerType'] = this.transformParameter(
      queryParameters['customerType'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get contact by email of eshop user
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} email - Email of eshop user
   */
  getContactByEmail(email, parameters = {}) {
    let path = '/contacts/{email}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{email}', email);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get contact by email of eshop user
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId -
   * @param {} newsId - id of article
   */
  getNewsAction(companyId, newsId, parameters = {}) {
    let path = '/{companyId}/news/{newsId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{newsId}', newsId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get news attachment download.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} newsId - ID of product
       * @param {} fullPath - fullPath of attachment
     */
  getNewsAttachmentDownloadAction(
    companyId,
    newsId,
    fullPath,
    parameters = {},
  ) {
    let path = '/{companyId}/news/{newsId}/attachment/{fullPath}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{newsId}', newsId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get news filters.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
     */
  getNewsFiltersAction(companyId, parameters = {}) {
    let path = '/{companyId}/news-filters';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get news article by url
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId -
   * @param {} newsUrl - url of article
   */
  getUrlNewsAction(companyId, newsUrl, parameters = {}) {
    let path = '/{companyId}/news-url/{newsUrl}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{newsUrl}', newsUrl);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get news list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.categories - Categories of news
     */
  getNewsListAction(companyId, parameters = {}) {
    let path = '/{companyId}/news';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['categories'] !== 'undefined') {
      queryParameters['categories'] = parameters['categories'];
    }

    queryParameters['categories'] = this.transformParameter(
      queryParameters['categories'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get banners list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} pageId - 
       * @param {} parameters.onlyOneToOne - Only oneToOne banner
     */
  getBannersAction(companyId, pageId, parameters = {}) {
    let path = '/{pageId}/{companyId}/banners';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{pageId}', pageId);

    if (typeof parameters['onlyOneToOne'] !== 'undefined') {
      queryParameters['onlyOneToOne'] = parameters['onlyOneToOne'];
    }

    queryParameters['onlyOneToOne'] = this.transformParameter(
      queryParameters['onlyOneToOne'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product documentation download.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productId - ID of product
       * @param {} documentationId - DocumentationId
     */
  getProductDocumentationDownloadAction(
    companyId,
    productId,
    documentationId,
    parameters = {},
  ) {
    let path =
      '/{companyId}/products/{productId}/documentation/{documentationId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    path = path.replace('{documentationId}', documentationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of shades for catalog products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productId - ID of product
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.colorCardId - Color card ID
       * @param {} parameters.colorCategoryId - Color category ID
       * @param {} parameters.q - Qickseach by product name, ID, Code ...
       * @param {} parameters.onlyFavorit - Only favorit switch
       * @param {} parameters.addPrices - Add tinting prices to shades
     */
  getCatalogProductShadesAction(companyId, productId, parameters = {}) {
    let path = '/{companyId}/products/{productId}/shades';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['colorCardId'] !== 'undefined') {
      queryParameters['colorCardId'] = parameters['colorCardId'];
    }

    queryParameters['colorCardId'] = this.transformParameter(
      queryParameters['colorCardId'],
    );

    if (typeof parameters['colorCategoryId'] !== 'undefined') {
      queryParameters['colorCategoryId'] = parameters['colorCategoryId'];
    }

    queryParameters['colorCategoryId'] = this.transformParameter(
      queryParameters['colorCategoryId'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['onlyFavorit'] !== 'undefined') {
      queryParameters['onlyFavorit'] = parameters['onlyFavorit'];
    }

    queryParameters['onlyFavorit'] = this.transformParameter(
      queryParameters['onlyFavorit'],
    );

    if (typeof parameters['addPrices'] !== 'undefined') {
      queryParameters['addPrices'] = parameters['addPrices'];
    }

    queryParameters['addPrices'] = this.transformParameter(
      queryParameters['addPrices'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of shade

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productId - ID of product
       * @param {} shadeId - ID of shade
       * @param {} parameters.colorcardId - Id of colorcard
     */
  getCatalogProductShadeAction(companyId, productId, shadeId, parameters = {}) {
    let path = '/{companyId}/products/{productId}/shades/{shadeId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    path = path.replace('{shadeId}', shadeId);

    if (typeof parameters['colorcardId'] !== 'undefined') {
      queryParameters['colorcardId'] = parameters['colorcardId'];
    }

    queryParameters['colorcardId'] = this.transformParameter(
      queryParameters['colorcardId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product multimedia.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} productId - ID of product
       * @param {} parameters.type - Filter by type
       * @param {} parameters.filters - 
     */
  getProductMultimediaAction(companyId, productId, parameters = {}) {
    let path = '/{companyId}/products/{productId}/multimedia';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{productId}', productId);

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['type'] === 'undefined') {
      throw Error('Missing required parameter: type');
    }

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get list of question to product

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productId - ID of product
     */
  getProductQuestionsAction(companyId, productId, parameters = {}) {
    let path = '/{companyId}/products/{productId}/questions';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Post new question to product

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productId - ID of product
       * @param {} parameters.body - 
     */
  postProductQuestionAction(companyId, productId, parameters = {}) {
    let path = '/{companyId}/products/{productId}/questions';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Modify question

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} questionId - ID of question
       * @param {} parameters.body - 
     */
  putProductQuestionLikeAction(companyId, questionId, parameters = {}) {
    let path = '/{companyId}/products/question/{questionId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{questionId}', questionId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Post new product review

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.body - 
     */
  postProductReviewAction(companyId, parameters = {}) {
    let path = '/{companyId}/products/review';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Can user review product

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productId - ID of product
     */
  getCanReviewProductAction(companyId, productId, parameters = {}) {
    let path = '/{companyId}/products/{productId}/review';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product reviews list

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productId - ID of product
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.rating - Rating filter
     */
  getProdcuctReviewsAction(companyId, productId, parameters = {}) {
    let path = '/{companyId}/products/{productId}/reviews';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['rating'] !== 'undefined') {
      queryParameters['rating'] = parameters['rating'];
    }

    queryParameters['rating'] = this.transformParameter(
      queryParameters['rating'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Save image to product review

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} reviewId - 
       * @param {} parameters.body - 
     */
  postProductReviewImageAction(companyId, reviewId, parameters = {}) {
    let path = '/{companyId}/products/reviews/{reviewId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{reviewId}', reviewId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get rating token status

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} ratingToken - Rating token
     */
  getUserRatingAction(companyId, ratingToken, parameters = {}) {
    let path = '/{companyId}/rating/{ratingToken}/status';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{ratingToken}', ratingToken);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get rating token status

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} ratingToken - Rating token
       * @param {} parameters.body - 
     */
  postUserOrderRatingAction(companyId, ratingToken, parameters = {}) {
    let path = '/{companyId}/rating/{ratingToken}/create';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{ratingToken}', ratingToken);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get file to download by downloadId.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} downloadId - 
     */
  getDownloaderAction(downloadId, parameters = {}) {
    let path = '/downloader/{downloadId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{downloadId}', downloadId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get related products according to product ID.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productId - Url of a product
       * @param {} parameters.lang - Translate values to selected lang
     */
  getRelatedProductsAction(companyId, productId, parameters = {}) {
    let path = '/{companyId}/products/{productId}/related-products';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get most bought products according to product ID.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productId - Url of a product
       * @param {} parameters.lang - Translate values to selected lang
     */
  getMostBoughtWithProductsAction(companyId, productId, parameters = {}) {
    let path = '/{companyId}/products/{productId}/most-bought-products';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product multimedia download.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productId - ID of product
       * @param {} multimediaId - Multimedia ID
     */
  getProductMultimediaDownloadAction(
    companyId,
    productId,
    multimediaId,
    parameters = {},
  ) {
    let path =
      '/{companyId}/products/{productId}/multimedia/{multimediaId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    path = path.replace('{multimediaId}', multimediaId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product references

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productId - ID of a product
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
     */
  getProductReferencesAction(companyId, productId, parameters = {}) {
    let path = '/{companyId}/products/{productId}/references';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get products variants.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.body - 
     */
  getProductsVariantsAction(companyId, parameters = {}) {
    let path = '/{companyId}/products/products-variants';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product reference detail

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} referenceId - ID of reference
     */
  getProductReferenceDetailAction(companyId, referenceId, parameters = {}) {
    let path = '/{companyId}/reference/{referenceId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{referenceId}', referenceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get products brands.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getProductsBrandsAction(parameters = {}) {
    let path = '/products-brands';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Register new user to xPaint eshop.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.body - 
     */
  registrationAction(companyId, parameters = {}) {
    let path = '/{companyId}/registration';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get informations about user registration.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} token - Registration token
     */
  registrationStatusAction(token, parameters = {}) {
    let path = '/registration/{token}/status';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{token}', token);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Verify user registration in eshop.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} token - Registration token
     */
  registrationVerifyAction(token, parameters = {}) {
    let path = '/registration/{token}/verify';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{token}', token);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Restore password.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  restorePasswordRequestAction(parameters = {}) {
    let path = '/restore-password';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Activate account and set new password.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} token - Activate token
       * @param {} parameters.body - 
     */
  postAccountActivationAction(token, parameters = {}) {
    let path = '/activate-account/{token}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{token}', token);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Re-send activate mail account.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} token - Activate token
     */
  postAccountActivationMailAction(token, parameters = {}) {
    let path = '/activate-account/{token}/mail-send';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{token}', token);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Verify account activate token.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} token - Activate token
     */
  getVerifyActivationTokenAction(token, parameters = {}) {
    let path = '/activate-account/{token}/verify';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{token}', token);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Request to restore lost password.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} token - Restore password token
       * @param {} parameters.body - 
     */
  restorePasswordAction(token, parameters = {}) {
    let path = '/restore-password/{token}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{token}', token);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Register new user to xPaint eshop from store.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.body - 
     */
  postRegisterAtStoreAction(companyId, parameters = {}) {
    let path = '/{companyId}/registration-at-store';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new cart for user.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.body - 
     */
  postCartAction(companyId, parameters = {}) {
    let path = '/{companyId}/cart';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get cart details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} cartId - ID of cart
       * @param {} parameters.updateSaleProducts - 
     */
  getCartAction(companyId, cartId, parameters = {}) {
    let path = '/{companyId}/cart/{cartId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{cartId}', cartId);

    if (typeof parameters['updateSaleProducts'] !== 'undefined') {
      queryParameters['updateSaleProducts'] = parameters['updateSaleProducts'];
    }

    queryParameters['updateSaleProducts'] = this.transformParameter(
      queryParameters['updateSaleProducts'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Add new item to cart.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} cartId - ID of cart
       * @param {} parameters.addToExisting - 
       * @param {} parameters.catalogProductId - 
       * @param {} parameters.body - 
     */
  postCartItemAction(companyId, cartId, parameters = {}) {
    let path = '/{companyId}/cart/{cartId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{cartId}', cartId);

    if (typeof parameters['addToExisting'] !== 'undefined') {
      queryParameters['addToExisting'] = parameters['addToExisting'];
    }

    queryParameters['addToExisting'] = this.transformParameter(
      queryParameters['addToExisting'],
    );

    if (typeof parameters['catalogProductId'] !== 'undefined') {
      queryParameters['catalogProductId'] = parameters['catalogProductId'];
    }

    queryParameters['catalogProductId'] = this.transformParameter(
      queryParameters['catalogProductId'],
    );

    if (typeof parameters['catalogProductId'] === 'undefined') {
      throw Error('Missing required parameter: catalogProductId');
    }

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update cart metadata.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} cartId - ID of cart
       * @param {} parameters.body - 
     */
  putCartAction(companyId, cartId, parameters = {}) {
    let path = '/{companyId}/cart/{cartId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{cartId}', cartId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete cart.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - ID of cart
     */
  deleteCartAction(cartId, parameters = {}) {
    let path = '/{companyId}/cart/{cartId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{cartId}', cartId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update cart item.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} cartId - ID of cart
       * @param {} productId - ID of product
       * @param {} parameters.colorCode - 
       * @param {} parameters.mainId - 
       * @param {} parameters.relatedToMainId - 
       * @param {} parameters.isWh010Sale - 
       * @param {} parameters.body - 
     */
  putCartItemAction(companyId, cartId, productId, parameters = {}) {
    let path = '/{companyId}/cart/{cartId}/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{cartId}', cartId);

    path = path.replace('{productId}', productId);

    if (typeof parameters['colorCode'] !== 'undefined') {
      queryParameters['colorCode'] = parameters['colorCode'];
    }

    queryParameters['colorCode'] = this.transformParameter(
      queryParameters['colorCode'],
    );

    if (typeof parameters['mainId'] !== 'undefined') {
      queryParameters['mainId'] = parameters['mainId'];
    }

    queryParameters['mainId'] = this.transformParameter(
      queryParameters['mainId'],
    );

    if (typeof parameters['relatedToMainId'] !== 'undefined') {
      queryParameters['relatedToMainId'] = parameters['relatedToMainId'];
    }

    queryParameters['relatedToMainId'] = this.transformParameter(
      queryParameters['relatedToMainId'],
    );

    if (typeof parameters['isWh010Sale'] !== 'undefined') {
      queryParameters['is_wh010_sale'] = parameters['isWh010Sale'];
    }

    queryParameters['is_wh010_sale'] = this.transformParameter(
      queryParameters['is_wh010_sale'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete cart item.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} cartId - ID of cart
       * @param {} productId - ID of product
       * @param {} parameters.campaignProduct - 
       * @param {} parameters.colorCode - 
       * @param {} parameters.mainId - 
       * @param {} parameters.relatedToMainId - 
       * @param {} parameters.isWh010Sale - 
     */
  deleteCartItemAction(companyId, cartId, productId, parameters = {}) {
    let path = '/{companyId}/cart/{cartId}/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{cartId}', cartId);

    path = path.replace('{productId}', productId);

    if (typeof parameters['campaignProduct'] !== 'undefined') {
      queryParameters['campaignProduct'] = parameters['campaignProduct'];
    }

    queryParameters['campaignProduct'] = this.transformParameter(
      queryParameters['campaignProduct'],
    );

    if (typeof parameters['colorCode'] !== 'undefined') {
      queryParameters['colorCode'] = parameters['colorCode'];
    }

    queryParameters['colorCode'] = this.transformParameter(
      queryParameters['colorCode'],
    );

    if (typeof parameters['mainId'] !== 'undefined') {
      queryParameters['mainId'] = parameters['mainId'];
    }

    queryParameters['mainId'] = this.transformParameter(
      queryParameters['mainId'],
    );

    if (typeof parameters['relatedToMainId'] !== 'undefined') {
      queryParameters['relatedToMainId'] = parameters['relatedToMainId'];
    }

    queryParameters['relatedToMainId'] = this.transformParameter(
      queryParameters['relatedToMainId'],
    );

    if (typeof parameters['isWh010Sale'] !== 'undefined') {
      queryParameters['is_wh010_sale'] = parameters['isWh010Sale'];
    }

    queryParameters['is_wh010_sale'] = this.transformParameter(
      queryParameters['is_wh010_sale'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete cart item.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} cartId - ID of cart
       * @param {} productId - ID of product
       * @param {} parameters.campaignId - 
       * @param {} parameters.benefitProductId - 
       * @param {} parameters.value - 
     */
  deleteCartBenefitItemAction(companyId, cartId, productId, parameters = {}) {
    let path = '/{companyId}/cart/{cartId}/{productId}/benefit-product';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{cartId}', cartId);

    path = path.replace('{productId}', productId);

    if (typeof parameters['campaignId'] !== 'undefined') {
      queryParameters['campaignId'] = parameters['campaignId'];
    }

    queryParameters['campaignId'] = this.transformParameter(
      queryParameters['campaignId'],
    );

    if (typeof parameters['benefitProductId'] !== 'undefined') {
      queryParameters['benefitProductId'] = parameters['benefitProductId'];
    }

    queryParameters['benefitProductId'] = this.transformParameter(
      queryParameters['benefitProductId'],
    );

    if (typeof parameters['value'] !== 'undefined') {
      queryParameters['value'] = parameters['value'];
    }

    queryParameters['value'] = this.transformParameter(
      queryParameters['value'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Add new item to cart.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} cartId - ID of cart
       * @param {} packageId - Id of package
     */
  postCartPackageAction(companyId, cartId, packageId, parameters = {}) {
    let path = '/{companyId}/cart/{cartId}/packages/{packageId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{cartId}', cartId);

    path = path.replace('{packageId}', packageId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update cart item.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} cartId - ID of cart
       * @param {} packageId - Id of package
       * @param {} parameters.body - 
     */
  putCartPackageAction(companyId, cartId, packageId, parameters = {}) {
    let path = '/{companyId}/cart/{cartId}/packages/{packageId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{cartId}', cartId);

    path = path.replace('{packageId}', packageId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete cart item.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} cartId - ID of cart
       * @param {} packageId - Id of package
     */
  deleteCartPackageAction(companyId, cartId, packageId, parameters = {}) {
    let path = '/{companyId}/cart/{cartId}/packages/{packageId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{cartId}', cartId);

    path = path.replace('{packageId}', packageId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Add voucher to cart.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} cartId - ID of cart
       * @param {} voucher - ID of cart
     */
  postCartVoucherAction(companyId, cartId, voucher, parameters = {}) {
    let path = '/{companyId}/cart-voucher/{cartId}/{voucher}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{cartId}', cartId);

    path = path.replace('{voucher}', voucher);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete cart voucher.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} cartId - ID of cart
       * @param {} voucher - ID of cart
     */
  deleteCartVoucherAction(companyId, cartId, voucher, parameters = {}) {
    let path = '/{companyId}/cart-voucher/{cartId}/{voucher}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{cartId}', cartId);

    path = path.replace('{voucher}', voucher);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update cart item.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} cartId - ID of cart
       * @param {} parameters.serviceFeeId - 
     */
  putSpecialCartItemAction(companyId, cartId, parameters = {}) {
    let path = '/{companyId}/cart/{cartId}/service-fee';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{cartId}', cartId);

    if (typeof parameters['serviceFeeId'] !== 'undefined') {
      queryParameters['serviceFeeId'] = parameters['serviceFeeId'];
    }

    queryParameters['serviceFeeId'] = this.transformParameter(
      queryParameters['serviceFeeId'],
    );

    if (typeof parameters['serviceFeeId'] === 'undefined') {
      throw Error('Missing required parameter: serviceFeeId');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete cart item.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} cartId - ID of cart
       * @param {} parameters.serviceFeeId - 
     */
  deleteSpecialCartItemAction(companyId, cartId, parameters = {}) {
    let path = '/{companyId}/cart/{cartId}/service-fee';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{cartId}', cartId);

    if (typeof parameters['serviceFeeId'] !== 'undefined') {
      queryParameters['serviceFeeId'] = parameters['serviceFeeId'];
    }

    queryParameters['serviceFeeId'] = this.transformParameter(
      queryParameters['serviceFeeId'],
    );

    if (typeof parameters['serviceFeeId'] === 'undefined') {
      throw Error('Missing required parameter: serviceFeeId');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Add new item to cart.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} cartId - ID of cart
       * @param {} parameters.addAsPackage - 
       * @param {} parameters.body - 
     */
  postCartItemsAction(companyId, cartId, parameters = {}) {
    let path = '/{companyId}/cart-multiple/{cartId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{cartId}', cartId);

    if (typeof parameters['addAsPackage'] !== 'undefined') {
      queryParameters['addAsPackage'] = parameters['addAsPackage'];
    }

    queryParameters['addAsPackage'] = this.transformParameter(
      queryParameters['addAsPackage'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Post order.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.body - 
     */
  postOrderAction(companyId, parameters = {}) {
    let path = '/{companyId}/order';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get eshop redirect map.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
     */
  getEshopRedirectMap(companyId, parameters = {}) {
    let path = '/{companyId}/redirect-map';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get user orders list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.status - 
       * @param {} parameters.dateRange - 
       * @param {} parameters.source - 
     */
  getUserOrdersAction(companyId, parameters = {}) {
    let path = '/{companyId}/orders';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    queryParameters['dateRange'] = 'lastMonth';

    if (typeof parameters['dateRange'] !== 'undefined') {
      queryParameters['dateRange'] = parameters['dateRange'];
    }

    queryParameters['dateRange'] = this.transformParameter(
      queryParameters['dateRange'],
    );

    if (typeof parameters['source'] !== 'undefined') {
      queryParameters['source'] = parameters['source'];
    }

    queryParameters['source'] = this.transformParameter(
      queryParameters['source'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get detail of user order.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} orderId - ID NAV order
       * @param {} parameters.includeUser - Include user info
     */
  getUserOrderAction(companyId, orderId, parameters = {}) {
    let path = '/{companyId}/orders/{orderId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (typeof parameters['includeUser'] !== 'undefined') {
      queryParameters['includeUser'] = parameters['includeUser'];
    }

    queryParameters['includeUser'] = this.transformParameter(
      queryParameters['includeUser'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get user orders list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
     */
  getUserPurchasesAction(companyId, parameters = {}) {
    let path = '/{companyId}/purchases';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get detail of user purchase.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} purchaseId - id of purchase
     */
  getUserPurchaseAction(companyId, purchaseId, parameters = {}) {
    let path = '/{companyId}/purchases/{purchaseId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{purchaseId}', purchaseId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get token from GoPay API.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
     */
  getGoPayTokenAction(companyId, parameters = {}) {
    let path = '/{companyId}/gopay/token';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Starts GoPay payment.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.body - 
     */
  postGoPayPaymentAction(companyId, parameters = {}) {
    let path = '/{companyId}/gopay/payments/payment';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get GoPay payment status.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} paymentId - ID of GoPay payment
     */
  getGoPayPaymentStatusAction(companyId, paymentId, parameters = {}) {
    let path = '/{companyId}/gopay/payments/payment/{paymentId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{paymentId}', paymentId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Starts GoPay payment.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} orderId - ID of eshopOrder
       * @param {} parameters.body - 
     */
  postOrderGoPayPaymentAction(companyId, orderId, parameters = {}) {
    let path = '/{companyId}/orders/{orderId}/gopay/payment';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    if (typeof parameters['body'] === 'undefined') {
      throw Error('Missing required parameter: body');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get GoPay payment status by NAV order.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} orderId - ID of eshopOrder
     */
  getOrderGoPayPaymentStatusAction(companyId, orderId, parameters = {}) {
    let path = '/{companyId}/orders/{orderId}/gopay/payment/status';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get products available filters.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.filtersForFilters - Filters to be applied
       * @param {} parameters.category - Category URL
       * @param {} parameters.total - 
       * @param {} parameters.priceFrom - Price from
       * @param {} parameters.priceTo - Price to
     */
  getProductsFiltersAction(companyId, parameters = {}) {
    let path = '/{companyId}/filters';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['filtersForFilters'] !== 'undefined') {
      queryParameters['filtersForFilters'] = parameters['filtersForFilters'];
    }

    queryParameters['filtersForFilters'] = this.transformParameter(
      queryParameters['filtersForFilters'],
    );

    if (typeof parameters['category'] !== 'undefined') {
      queryParameters['category'] = parameters['category'];
    }

    queryParameters['category'] = this.transformParameter(
      queryParameters['category'],
    );

    if (typeof parameters['total'] !== 'undefined') {
      queryParameters['total'] = parameters['total'];
    }

    queryParameters['total'] = this.transformParameter(
      queryParameters['total'],
    );

    if (typeof parameters['priceFrom'] !== 'undefined') {
      queryParameters['priceFrom'] = parameters['priceFrom'];
    }

    queryParameters['priceFrom'] = this.transformParameter(
      queryParameters['priceFrom'],
    );

    if (typeof parameters['priceTo'] !== 'undefined') {
      queryParameters['priceTo'] = parameters['priceTo'];
    }

    queryParameters['priceTo'] = this.transformParameter(
      queryParameters['priceTo'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get favourites products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
     */
  getProductsFavouritesAction(companyId, parameters = {}) {
    let path = '/{companyId}/favourite-products';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of product types which can be tinted.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
     */
  getTintingTypesAction(companyId, parameters = {}) {
    let path = '/{companyId}/tinting-types';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.categoryName - Category of publication name
       * @param {} parameters.q - Qickseach by product name, ID, Code ...
       * @param {} parameters.sorter - Sorter
       * @param {} parameters.useVat - Use price with VAT
       * @param {} parameters.categoryName - Category of publication name
       * @param {} parameters.filters - Filters to be applied
     */
  getProductsMinMaxPriceAction(companyId, parameters = {}) {
    let path = '/{companyId}/products-min-max-prices';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['categoryName'] !== 'undefined') {
      queryParameters['categoryName'] = parameters['categoryName'];
    }

    queryParameters['categoryName'] = this.transformParameter(
      queryParameters['categoryName'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['useVat'] !== 'undefined') {
      queryParameters['useVat'] = parameters['useVat'];
    }

    queryParameters['useVat'] = this.transformParameter(
      queryParameters['useVat'],
    );

    if (typeof parameters['categoryName'] !== 'undefined') {
      queryParameters['categoryName'] = parameters['categoryName'];
    }

    queryParameters['categoryName'] = this.transformParameter(
      queryParameters['categoryName'],
    );

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * GoPay payment notification.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.id - GoPAY ID
     */
  postGoPayPaymentNotifyAction(companyId, parameters = {}) {
    let path = '/{companyId}/gopay/payment/notify';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get fees list

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.lang - lang of news articles
     */
  getFeesAction(companyId, parameters = {}) {
    let path = '/{companyId}/service-fees';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Starts GoPay payment.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} orderId - ID of eshopOrder
     */
  getOrderPayBySquarePaymentAction(companyId, orderId, parameters = {}) {
    let path = '/{companyId}/orders/{orderId}/pay-by-square/payment';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Return order comments.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId -
   * @param {} orderId - ID of eshopOrder
   */
  getEshopOrderCommentsAction(companyId, orderId, parameters = {}) {
    let path = '/{companyId}/orders/{orderId}/commnets';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new comment to order

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} orderId - ID of eshopOrder
       * @param {} parameters.body - 
     */
  postOrderCommentAction(companyId, orderId, parameters = {}) {
    let path = '/{companyId}/orders/{orderId}/commnets';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get order courier info and tracking info

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} orderId - ID of eshopOrder
     */
  getOrderCourierInfoAction(companyId, orderId, parameters = {}) {
    let path = '/{companyId}/orders/{orderId}/courier-info';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get order file.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} orderId - ID NAV order
       * @param {} parameters.type - Type of download
     */
  getUserOrderDownloadAction(companyId, orderId, parameters = {}) {
    let path = '/{companyId}/orders/{orderId}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters['type'] = 'pdf';

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get detail of user discounts.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
     */
  getUserDiscounts(companyId, parameters = {}) {
    let path = '/{companyId}/user/discounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get detail of user discounts.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
     */
  getUserLoyaltyDiscountsAction(companyId, parameters = {}) {
    let path = '/{companyId}/user/loyalty-discounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get loyalty discounts.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
     */
  getLoyaltyDiscountsAction(companyId, parameters = {}) {
    let path = '/{companyId}/loyalty-discounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get public order.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} orderToken - Order Token
     */
  getPublicOrder(companyId, orderToken, parameters = {}) {
    let path = '/{companyId}/public-orders/{orderToken}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderToken}', orderToken);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update user type.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} type - xpaint_customer_type
     */
  putChangeCustomerTypeAction(companyId, type, parameters = {}) {
    let path = '/{companyId}/user/change-type/{type}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{type}', type);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Authorize user with google token.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.token - Google account token
       * @param {} parameters.redirectUri - Redirect uri
       * @param {} parameters.state - State uri
       * @param {} parameters.cardId - Id of actual card
       * @param {} parameters.activationToken - Activation account token
     */
  verifyGoogleUserAction(companyId, parameters = {}) {
    let path = '/{companyId}/user-google-oauth';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['token'] !== 'undefined') {
      queryParameters['token'] = parameters['token'];
    }

    queryParameters['token'] = this.transformParameter(
      queryParameters['token'],
    );

    if (typeof parameters['token'] === 'undefined') {
      throw Error('Missing required parameter: token');
    }

    if (typeof parameters['redirectUri'] !== 'undefined') {
      queryParameters['redirectUri'] = parameters['redirectUri'];
    }

    queryParameters['redirectUri'] = this.transformParameter(
      queryParameters['redirectUri'],
    );

    if (typeof parameters['redirectUri'] === 'undefined') {
      throw Error('Missing required parameter: redirectUri');
    }

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['state'] === 'undefined') {
      throw Error('Missing required parameter: state');
    }

    if (typeof parameters['cardId'] !== 'undefined') {
      queryParameters['cardId'] = parameters['cardId'];
    }

    queryParameters['cardId'] = this.transformParameter(
      queryParameters['cardId'],
    );

    if (typeof parameters['activationToken'] !== 'undefined') {
      queryParameters['activationToken'] = parameters['activationToken'];
    }

    queryParameters['activationToken'] = this.transformParameter(
      queryParameters['activationToken'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get order invoice file.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} orderId - ID NAV order
     */
  getOrderInvoiceAction(companyId, orderId, parameters = {}) {
    let path = '/{companyId}/orders/{orderId}/invoice/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Retry start to process order.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} orderRetryId - ID of backup order data
     */
  postOrderRetryAction(orderRetryId, parameters = {}) {
    let path = '/orders/retry/{orderRetryId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{orderRetryId}', orderRetryId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Retry orders list.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getOrderRetryListAction(parameters = {}) {
    let path = '/orders/retry';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get notifications list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
     */
  getNotificationsAction(companyId, parameters = {}) {
    let path = '/{companyId}/notifications';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get products inventory.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.productId - ID of product
       * @param {} parameters.stockId - Stocks
     */
  getProductsInventoryAction(companyId, parameters = {}) {
    let path = '/{companyId}/inventory';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['productId'] !== 'undefined') {
      queryParameters['productId'] = parameters['productId'];
    }

    queryParameters['productId'] = this.transformParameter(
      queryParameters['productId'],
    );

    if (typeof parameters['productId'] === 'undefined') {
      throw Error('Missing required parameter: productId');
    }

    if (typeof parameters['stockId'] !== 'undefined') {
      queryParameters['stockId'] = parameters['stockId'];
    }

    queryParameters['stockId'] = this.transformParameter(
      queryParameters['stockId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get warranty claims action.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
     */
  getCustomerWarrantyClaimsAction(companyId, parameters = {}) {
    let path = '/{companyId}/warranty-claims';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get warranty claim detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} claimId - ID of eshopOrder
     */
  getCustomerWarrantyClaimAction(companyId, claimId, parameters = {}) {
    let path = '/{companyId}/warranty-claims/{claimId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{claimId}', claimId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get customer purchased products

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
     */
  getCustomerPurchasedProducts(companyId, parameters = {}) {
    let path = '/{companyId}/customer-purchased-products';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create return product request.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} orderId - ID of eshopOrder
       * @param {} productId - ID of product
       * @param {} parameters.body - 
     */
  postReturnProductAction(companyId, orderId, productId, parameters = {}) {
    let path = '/{companyId}/warranty-claims-return/{productId}/{orderId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    path = path.replace('{productId}', productId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create file for warrantyClaim

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} orderId - ID of eshopOrder
       * @param {} claimId - ID of eshopOrder
       * @param {} parameters.body - 
     */
  postWarrantyClaimFileAction(companyId, orderId, claimId, parameters = {}) {
    let path = '/{companyId}/warranty-claims-file/{claimId}/{orderId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    path = path.replace('{claimId}', claimId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get warranty claim file download

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} claimId - ID of eshopOrder
       * @param {} fullPath - fullPath of attachment
     */
  getWarrantyClaimFileDownloadAction(
    companyId,
    claimId,
    fullPath,
    parameters = {},
  ) {
    let path =
      '/{companyId}/warranty-claims-file/{claimId}/{fullPath}/download';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{claimId}', claimId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create claim product request.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} orderId - ID of eshopOrder
       * @param {} productId - ID of product
       * @param {} parameters.body - 
     */
  postClaimProductAction(companyId, orderId, productId, parameters = {}) {
    let path = '/{companyId}/warranty-claims-claim/{productId}/{orderId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{orderId}', orderId);

    path = path.replace('{productId}', productId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get shop list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
     */
  getShopListAction(companyId, parameters = {}) {
    let path = '/{companyId}/shops';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get shop.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} shopId - Id of shop
     */
  getShopAction(companyId, shopId, parameters = {}) {
    let path = '/{companyId}/shops/{shopId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{shopId}', shopId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get codelists by type.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.type - Type (id) of codelists
     */
  getCodelistItemsAction(companyId, parameters = {}) {
    let path = '/{companyId}/codelist-items';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['type'] === 'undefined') {
      throw Error('Missing required parameter: type');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get videos action.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} productId - ID of product
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
     */
  getVideosAction(companyId, productId, parameters = {}) {
    let path = '/{companyId}/get-videos/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{productId}', productId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get multimedia by id.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} multimediaId - Multimedia ID
     */
  getMultimediaByIdAction(companyId, multimediaId, parameters = {}) {
    let path = '/{companyId}/multimedia/{multimediaId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{multimediaId}', multimediaId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get paint systems.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.category - 
       * @param {} parameters.sorter - Sorter
       * @param {} parameters.categoryName - Category of publication name
       * @param {} parameters.filters - Filters to be applied
       * @param {} parameters.catalogProductId - Id of used catalog product
     */
  getPaintSystemsAction(companyId, parameters = {}) {
    let path = '/{companyId}/paint-systems';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['category'] !== 'undefined') {
      queryParameters['category'] = parameters['category'];
    }

    queryParameters['category'] = this.transformParameter(
      queryParameters['category'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['categoryName'] !== 'undefined') {
      queryParameters['categoryName'] = parameters['categoryName'];
    }

    queryParameters['categoryName'] = this.transformParameter(
      queryParameters['categoryName'],
    );

    if (typeof parameters['filters'] !== 'undefined') {
      queryParameters['filters'] = parameters['filters'];
    }

    queryParameters['filters'] = this.transformParameter(
      queryParameters['filters'],
    );

    if (typeof parameters['catalogProductId'] !== 'undefined') {
      queryParameters['catalogProductId'] = parameters['catalogProductId'];
    }

    queryParameters['catalogProductId'] = this.transformParameter(
      queryParameters['catalogProductId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get paint systems subcategories.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
     */
  getPaintSystemSubcategories(companyId, parameters = {}) {
    let path = '/{companyId}/paint-systems-subcategories';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get paint systems filters.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.filtersForFilters - Filters to be applied
       * @param {} parameters.category - Category URL
     */
  getPaintSystemFiltersAction(companyId, parameters = {}) {
    let path = '/{companyId}/paint-systems-filters';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['filtersForFilters'] !== 'undefined') {
      queryParameters['filtersForFilters'] = parameters['filtersForFilters'];
    }

    queryParameters['filtersForFilters'] = this.transformParameter(
      queryParameters['filtersForFilters'],
    );

    if (typeof parameters['category'] !== 'undefined') {
      queryParameters['category'] = parameters['category'];
    }

    queryParameters['category'] = this.transformParameter(
      queryParameters['category'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get paint system details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} paintSystemCode - code of paint system
     */
  getPaintSystemAction(companyId, paintSystemCode, parameters = {}) {
    let path = '/{companyId}/paint-systems/{paintSystemCode}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{paintSystemCode}', paintSystemCode);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create paint system calculation.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} paintSystemCode - code of paint system
       * @param {} parameters.body - 
     */
  postPaintSystemCalculationAction(
    companyId,
    paintSystemCode,
    parameters = {},
  ) {
    let path = '/{companyId}/paint-systems/{paintSystemCode}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{paintSystemCode}', paintSystemCode);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get paint system calculations.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
     */
  getPaintSystemCalculationsAction(companyId, parameters = {}) {
    let path = '/{companyId}/paint-systems-calculations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get paint system calculation.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} calculationId - code of paint system
     */
  getPaintSystemCalculationAction(companyId, calculationId, parameters = {}) {
    let path = '/{companyId}/paint-systems-calculations/{calculationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{calculationId}', calculationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * List of security certificates types.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId -
   */
  getSecurityCertificatesAction(companyId, parameters = {}) {
    let path = '/{companyId}/security-certificates-types';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Save security certificate.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId -
   * @param {} parameters.body -
   */
  postCustomerSecurityCertificateAction(companyId, parameters = {}) {
    let path = '/{companyId}/security-certificates';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Get security certificate for customer.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} companyId -
   * @param {} parameters.onlyValid -
   * @param {} parameters.sorter -
   */
  getCustomerSecurityCertificatesAction(companyId, parameters = {}) {
    let path = '/{companyId}/security-certificates';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['onlyValid'] !== 'undefined') {
      queryParameters['onlyValid'] = parameters['onlyValid'];
    }

    queryParameters['onlyValid'] = this.transformParameter(
      queryParameters['onlyValid'],
    );

    queryParameters['sorter'] = '-created_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Upload security certificate PDF file.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} certificateId - Certificate ID
       * @param {} parameters.body - 
     */
  postCustomerSecurityCertificatePDFAction(
    companyId,
    certificateId,
    parameters = {},
  ) {
    let path = '/{companyId}/security-certificates/{certificateId}/upload';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{certificateId}', certificateId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Subscribe email to newsletter

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.body - 
     */
  postNewsletterSubscribeAction(companyId, parameters = {}) {
    let path = '/{companyId}/newsletter/subscribe';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Confirm subscribe email to newsletter

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} parameters.body - 
     */
  postNewsletterConfirmationAction(companyId, parameters = {}) {
    let path = '/{companyId}/newsletter/confirm';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Unsubscribe contact from newsletter

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
     */
  putNewsletterUnsubscribeContactAction(companyId, parameters = {}) {
    let path = '/{companyId}/newsletter/unsubscribe';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Unsubscribe contact from newsletter

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - 
       * @param {} token - Unsubscribe newsletter token
     */
  getNewsletterUnsubscribeAction(companyId, token, parameters = {}) {
    let path = '/{companyId}/newsletter/unsubscribe/{token}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{token}', token);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  openapiJson(parameters = {}) {
    let path = '/openapi.json';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
}

export default new Api();
export { Api };
