import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, injectGlobal } from 'styled-components';
import { ThemeAdmin } from 'oxyrion-ui/lib';
import ErrorBoundery from './ErrorBoundory';
import authorize from './Auth';
import { Provider } from './Store/index';
import App from './App';

const Application = authorize(App);

injectGlobal`  
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif
  }
`;

ReactDOM.render(
  // <React.StrictMode>
  <ErrorBoundery>
    <ThemeProvider theme={ThemeAdmin}>
      <Provider>
        <Application />
      </Provider>
    </ThemeProvider>
  </ErrorBoundery>,
  // </React.StrictMode>,
  document.getElementById('root'),
);
