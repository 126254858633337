import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Button, Message, Loader, Textarea } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
  ButtonRelativeWrapper,
  StyledInput,
  StyledLabel,
  H2,
  Close,
} from '../../Components/ReusableComponents';
import LanguageSwitch from '../../Components/LanguageSwitch';
import SearchableSelect from '../../Components/SearchableSelect';
import { fetchMultimedia } from '../../Utils/ApiSearchFunctions';
import AdminAPI from '../../AdminAPI';
import API2 from '../../API2';

const Wrapper = styled.div`
  display: flex;
  padding: ${rem(12)};
  flex-direction: column;
  ${({ theme }) => theme.media.l`
    flex-direction: row;
    `};
`;

const InputWrapper = styled.div`
  width: ${rem(352)};
`;

const ContentWrapper = styled.div`
  display: flex;
  padding: ${rem(12)};
  width: ${rem(460)};
  margin-bottom: ${rem(20)};
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(15)};
`;

const SectionWrapper = styled.div`
  background-color: #f7f7f7;
  padding: ${rem(18)};
  border-radius: ${rem(4)};
  margin-bottom: ${rem(20)};
  display: flex;
  flex-direction: column;
`;

const ContactSection = styled.div`
  margin-top: ${rem(18)};
`;

const TitleWrapper = styled.div`
  padding-top: ${rem(12)};
  padding-bottom: ${rem(6)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Line = styled.div`
  height: ${rem(1)};
  max-width: ${rem(410)};
  background-color: #dfdfdf;
  margin-top: ${rem(12)};
`;

const ButtonWrapper = styled.div`
  margin-top: ${rem(12)};
`;

class AboutUsContact extends React.Component {
  constructor(props) {
    super(props);
    const translations = [{ lang: 'SK', value: '' }];
    this.state = {
      activeLang: 'SK',
      activeSectionLangs: [],
      saveLoading: false,
      loading: false,
      info: {
        company_description: translations,
        multimedium_title_image_id: null,
        multimedium_logo_id: null,
        sections: [
          {
            title: translations,
            short_description: translations,
            long_description: translations,
            brands: [],
          },
        ],
        contact: {
          company_name: translations,
          company_address: translations,
          phone_number: translations,
          email: translations,
          company_no: translations,
          vat_number: translations,
          bank_connection: translations,
          swift: translations,
          iban: translations,
          gps_coordinates: translations,
        },
        team_members: [
          {
            title: translations,
            members: [],
          },
        ],
      },
    };
  }

  async componentWillMount() {
    await this.fetchData();
    const { info } = this.state;
    const activeSectionLangs = info.sections.map(() => 'SK');

    this.setState({ activeSectionLangs });
  }

  async fetchData() {
    try {
      const data = await AdminAPI.getCompanyBaseInfoAction();

      let dataObj = null;

      if (data.sections.length > 0) {
        const products = await this.fetchProducts();
        const sectionArr = data.sections.map((section) => {
          const matchedProducts = section.brands.map((productId) => {
            return products.find((i) => i.value === productId);
          });
          return {
            ...section,
            brands: matchedProducts || [],
          };
        });
        if (sectionArr) {
          dataObj = {
            ...dataObj,
            sections: sectionArr,
          };
        }
      }

      const newData = Object.assign({}, data, {
        ...dataObj,
      });

      if (newData) {
        this.setState({
          info: newData,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  async saveInfo() {
    const { info } = this.state;

    this.setState({ saveLoading: true });

    const normalizedInfo = this.normalizeDataForApi(info);
    const params = {};
    params.body = {
      ...normalizedInfo,
    };

    try {
      await AdminAPI.putCompanyBaseInfoAction(params);

      this.setState({
        saveLoading: false,
        error: false,
        success: __('Údaje boli úspešne upravené'),
      });
    } catch (error) {
      this.setState({
        error: __('Údaje sa nepodarilo upraviť alebo vytvoriť'),
        success: false,
        loading: false,
      });
    }
  }

  normalizeDataForApi(info) {
    const { _id, updated_date, ...infos } = info;

    return {
      ...infos,
      multimedium_title_image_id: infos.multimedium_title_image_id
        ? infos.multimedium_title_image_id.value
        : null,
      multimedium_logo_id: infos.multimedium_title_image_id
        ? infos.multimedium_logo_id.value
        : null,
      sections: infos.sections.map((section) => ({
        ...section,
        brands: section.brands.map((product) => product.value || ''),
        image_id: section.image_id ? section.image_id.value : undefined,
      })),
    };
  }

  fetchProducts(query) {
    try {
      return API2.getProductsAction(this.props.firm, {
        q: query,
        limit: 10,
      }).then((res) => {
        return res.products.map((item) => ({
          value: item._id,
          label: item.Description,
          product: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  handleChange(e, field) {
    const { info } = this.state;

    const newData = Object.assign({}, info);

    newData[field] = e;

    this.setState({
      info: newData,
    });
  }

  handleLocalChange(e, lang, field) {
    const { info } = this.state;

    const fieldSplit = field.split('.');

    if (fieldSplit.length > 1) {
      const contactField = fieldSplit[1];

      const contactTranslation = info.contact[contactField].map((item) =>
        item.lang === lang ? { lang, value: e } : item,
      );
      if (!contactTranslation.find((item) => item.lang === lang)) {
        contactTranslation.push({ lang, value: e });
      }

      const newContactInfo = {
        ...info.contact,
        [contactField]: contactTranslation,
      };
      this.setState({
        info: {
          ...info,
          contact: newContactInfo,
        },
      });
      return;
    }
    const newTranslations = info[field].map((item) =>
      item.lang === lang ? { lang, value: e } : item,
    );
    if (!newTranslations.find((item) => item.lang === lang)) {
      newTranslations.push({ lang, value: e });
    }

    const newInfo = {
      ...info,
      [field]: newTranslations,
    };

    this.setState({
      info: newInfo,
    });
  }

  handleSectionLocalChange(e, lang, index, field) {
    const { info } = this.state;

    const newSections = [...info.sections];

    newSections[index] = {
      ...newSections[index],
      [field]: [
        ...newSections[index][field].filter((item) => item.lang !== lang),
        { lang, value: e },
      ],
    };

    const newTranslations = {
      ...info,
      sections: newSections,
    };

    this.setState({
      info: newTranslations,
    });
  }

  handleSectionChange(e, field, index) {
    const { info } = this.state;
    const newSections = [...info.sections];

    newSections[index] = {
      ...newSections[index],
      [field]: e,
    };

    const newInfo = {
      ...info,
      sections: newSections,
    };

    this.setState({
      info: newInfo,
    });
  }

  handleAddSection = () => {
    const translations = [{ lang: 'SK', value: '' }];
    this.setState((prevState) => ({
      info: {
        ...prevState.info,
        sections: [
          ...prevState.info.sections,
          {
            title: translations,
            short_description: translations,
            long_description: translations,
            brands: [],
          },
        ],
      },
      activeSectionLangs: [...prevState.activeSectionLangs, 'SK'],
    }));
  };

  handleRemoveSection = (indexToRemove) => {
    this.setState((prevState) => ({
      info: {
        ...prevState.info,
        sections: prevState.info.sections.filter(
          (_, index) => index !== indexToRemove,
        ),
      },
      activeSectionLangs: prevState.activeSectionLangs.filter(
        (_, i) => i !== indexToRemove,
      ),
    }));
  };

  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar
        history={history}
        name={__('Dejmark - korporátne stránky')}
      />
    );
  }

  render() {
    const {
      error,
      success,
      saveLoading,
      activeLang,
      loading,
      info,
      activeSectionLangs,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        {this.renderControlBar()}
        <Wrapper>
          <ContentWrapper>
            <SectionWrapper>
              <TitleWrapper>
                <H2>{__('O nás')}</H2>
              </TitleWrapper>
              <Row>
                <LanguageSwitch
                  onChange={(lang) => {
                    this.setState({
                      activeLang: lang,
                    });
                    return true;
                  }}
                  activeId={activeLang}
                />
              </Row>
              <InputWrapper>
                <StyledLabel>{__('Popis')}</StyledLabel>
                <Textarea
                  style={{
                    width: rem(330),
                    height: rem(80),
                    resize: 'vertical',
                    lineHeight: rem(18),
                  }}
                  placeholder={__('Popis')}
                  value={
                    info.company_description.filter(
                      (item) => item.lang === activeLang,
                    ).length > 0
                      ? info.company_description.filter(
                          (item) => item.lang === activeLang,
                        )[0].value
                      : ''
                  }
                  onChange={(e) =>
                    this.handleLocalChange(
                      e.target.value,
                      activeLang,
                      'company_description',
                    )
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <StyledLabel>{__('Titulný obrázok')}</StyledLabel>
                <SearchableSelect
                  loadOptions={(query) => {
                    return fetchMultimedia(query);
                  }}
                  value={info.multimedium_title_image_id}
                  placeholder={__('Titulný obrázok')}
                  handleOnChange={(e) =>
                    this.handleChange(e, 'multimedium_title_image_id')
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <StyledLabel> {__('Logo')}</StyledLabel>
                <SearchableSelect
                  loadOptions={(query) => {
                    return fetchMultimedia(query);
                  }}
                  value={info.multimedium_logo_id}
                  placeholder={__('Logo')}
                  handleOnChange={(e) =>
                    this.handleChange(e, 'multimedium_logo_id')
                  }
                />
              </InputWrapper>
              {info.sections.map((section, index) => (
                <ContactSection key={section}>
                  <Line />
                  <TitleWrapper>
                    <H2>
                      {__('O nás')} - {__('Sekcia')} {index + 1}.
                    </H2>
                    <Close
                      onClick={() => this.handleRemoveSection(index)}
                      src="/images/icons/close_transparent.svg"
                      height="20px"
                    />
                  </TitleWrapper>

                  <Row>
                    <LanguageSwitch
                      onChange={(lang) => {
                        const activeLangs = [...activeSectionLangs];
                        activeLangs[index] = lang;
                        this.setState({ activeSectionLangs: activeLangs });
                        return true;
                      }}
                      activeId={activeSectionLangs[index]}
                    />
                  </Row>

                  <InputWrapper>
                    <StyledLabel> {__('Nadpis')}</StyledLabel>
                    <StyledInput
                      placeholder={__('Nadpis')}
                      value={
                        info.sections[index].title.filter(
                          (item) => item.lang === activeSectionLangs[index],
                        ).length > 0
                          ? info.sections[index].title.filter(
                              (item) => item.lang === activeSectionLangs[index],
                            )[0].value
                          : ''
                      }
                      onChange={(e) => {
                        this.handleSectionLocalChange(
                          e.target.value,
                          activeSectionLangs[index],
                          index,
                          `title`,
                        );
                      }}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <StyledLabel> {__('Krátky popis')}</StyledLabel>
                    <Textarea
                      style={{
                        width: rem(330),
                        height: rem(60),
                        resize: 'vertical',
                        lineHeight: rem(18),
                      }}
                      placeholder={__('Krátky popis')}
                      value={
                        info.sections[index].short_description.filter(
                          (item) => item.lang === activeSectionLangs[index],
                        ).length > 0
                          ? info.sections[index].short_description.filter(
                              (item) => item.lang === activeSectionLangs[index],
                            )[0].value
                          : ''
                      }
                      onChange={(e) => {
                        this.handleSectionLocalChange(
                          e.target.value,
                          activeSectionLangs[index],
                          index,
                          `short_description`,
                        );
                      }}
                    />
                    B
                  </InputWrapper>
                  <InputWrapper>
                    <StyledLabel> {__('Dlhý popis')}</StyledLabel>
                    <Textarea
                      style={{
                        width: rem(330),
                        height: rem(80),
                        resize: 'vertical',
                        lineHeight: rem(18),
                      }}
                      placeholder={__('Dlhý popis')}
                      value={
                        info.sections[index].long_description.filter(
                          (item) => item.lang === activeSectionLangs[index],
                        ).length > 0
                          ? info.sections[index].long_description.filter(
                              (item) => item.lang === activeSectionLangs[index],
                            )[0].value
                          : ''
                      }
                      onChange={(e) => {
                        this.handleSectionLocalChange(
                          e.target.value,
                          activeSectionLangs[index],
                          index,
                          `long_description`,
                        );
                      }}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <StyledLabel>
                      {__('Produkty - pre všetky krajiny')}
                    </StyledLabel>
                    <SearchableSelect
                      loadOptions={(query) => this.fetchProducts(query)}
                      placeholder={__('Produkty')}
                      handleOnChange={(e) =>
                        this.handleSectionChange(e, 'brands', index)
                      }
                      value={info.sections[index].brands}
                      isMulti
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <StyledLabel>{__('Obrázok')}</StyledLabel>
                    <SearchableSelect
                      loadOptions={(query) => fetchMultimedia(query)}
                      value={info.sections[index].image_id}
                      placeholder={__('Obrázok')}
                      handleOnChange={(e) =>
                        this.handleSectionChange(e, 'image_id', index)
                      }
                    />
                  </InputWrapper>
                </ContactSection>
              ))}
            </SectionWrapper>

            <ButtonWrapper>
              <Button
                loading={loading}
                onClick={() => {
                  this.handleAddSection();
                }}
                primary
              >
                {__('Pridať sekciu')}
              </Button>
            </ButtonWrapper>
          </ContentWrapper>
          <ContentWrapper>
            <SectionWrapper>
              <TitleWrapper>
                <H2>{__('Kontakt')}</H2>
              </TitleWrapper>
              <Line />
              <InputWrapper>
                <StyledLabel>{__('Názov spoločnosti')}</StyledLabel>
                <StyledInput
                  placeholder={__('Názov spoločnosti')}
                  value={
                    info.contact.company_name.filter(
                      (item) => item.lang === activeLang,
                    ).length > 0
                      ? info.contact.company_name.filter(
                          (item) => item.lang === activeLang,
                        )[0].value
                      : ''
                  }
                  onChange={(e) =>
                    this.handleLocalChange(
                      e.target.value,
                      activeLang,
                      'contact.company_name',
                    )
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <StyledLabel>{__('Adresa spoločnosti')}</StyledLabel>
                <StyledInput
                  placeholder={__('Adresa spoločnosti')}
                  value={
                    info.contact.company_address.filter(
                      (item) => item.lang === activeLang,
                    ).length > 0
                      ? info.contact.company_address.filter(
                          (item) => item.lang === activeLang,
                        )[0].value
                      : ''
                  }
                  onChange={(e) =>
                    this.handleLocalChange(
                      e.target.value,
                      activeLang,
                      'contact.company_address',
                    )
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <StyledLabel>{__('Tel. kontakt')}</StyledLabel>
                <StyledInput
                  placeholder={__('Tel. kontakt')}
                  value={
                    info.contact.phone_number.filter(
                      (item) => item.lang === activeLang,
                    ).length > 0
                      ? info.contact.phone_number.filter(
                          (item) => item.lang === activeLang,
                        )[0].value
                      : ''
                  }
                  onChange={(e) =>
                    this.handleLocalChange(
                      e.target.value,
                      activeLang,
                      'contact.phone_number',
                    )
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <StyledLabel>{__('Email')}</StyledLabel>
                <StyledInput
                  placeholder={__('Email')}
                  value={
                    info.contact.email.filter(
                      (item) => item.lang === activeLang,
                    ).length > 0
                      ? info.contact.email.filter(
                          (item) => item.lang === activeLang,
                        )[0].value
                      : ''
                  }
                  onChange={(e) =>
                    this.handleLocalChange(
                      e.target.value,
                      activeLang,
                      'contact.email',
                    )
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <StyledLabel>{__('IČO')}</StyledLabel>
                <StyledInput
                  placeholder={__('IČO')}
                  value={
                    info.contact.company_no.filter(
                      (item) => item.lang === activeLang,
                    ).length > 0
                      ? info.contact.company_no.filter(
                          (item) => item.lang === activeLang,
                        )[0].value
                      : ''
                  }
                  onChange={(e) =>
                    this.handleLocalChange(
                      e.target.value,
                      activeLang,
                      'contact.company_no',
                    )
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <StyledLabel>{__('DIČ')}</StyledLabel>
                <StyledInput
                  placeholder={__('DIČ')}
                  value={
                    info.contact.vat_number.filter(
                      (item) => item.lang === activeLang,
                    ).length > 0
                      ? info.contact.vat_number.filter(
                          (item) => item.lang === activeLang,
                        )[0].value
                      : ''
                  }
                  onChange={(e) =>
                    this.handleLocalChange(
                      e.target.value,
                      activeLang,
                      'contact.vat_number',
                    )
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <StyledLabel>{__('Bankové spojenie')}</StyledLabel>
                <StyledInput
                  placeholder={__('Bankové spojenie')}
                  value={
                    info.contact.bank_connection.filter(
                      (item) => item.lang === activeLang,
                    ).length > 0
                      ? info.contact.bank_connection.filter(
                          (item) => item.lang === activeLang,
                        )[0].value
                      : ''
                  }
                  onChange={(e) =>
                    this.handleLocalChange(
                      e.target.value,
                      activeLang,
                      'contact.bank_connection',
                    )
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <StyledLabel>{__('Swift')}</StyledLabel>
                <StyledInput
                  placeholder={__('Swift')}
                  value={
                    info.contact.swift.filter(
                      (item) => item.lang === activeLang,
                    ).length > 0
                      ? info.contact.swift.filter(
                          (item) => item.lang === activeLang,
                        )[0].value
                      : ''
                  }
                  onChange={(e) =>
                    this.handleLocalChange(
                      e.target.value,
                      activeLang,
                      'contact.swift',
                    )
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <StyledLabel>{__('IBAN')}</StyledLabel>
                <StyledInput
                  placeholder={__('IBAN')}
                  value={
                    info.contact.iban.filter((item) => item.lang === activeLang)
                      .length > 0
                      ? info.contact.iban.filter(
                          (item) => item.lang === activeLang,
                        )[0].value
                      : ''
                  }
                  onChange={(e) =>
                    this.handleLocalChange(
                      e.target.value,
                      activeLang,
                      'contact.iban',
                    )
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <StyledLabel>{__('GPS súradnice')}</StyledLabel>
                <StyledInput
                  placeholder={__('GPS súradnice')}
                  value={
                    info.contact.gps_coordinates.filter(
                      (item) => item.lang === activeLang,
                    ).length > 0
                      ? info.contact.gps_coordinates.filter(
                          (item) => item.lang === activeLang,
                        )[0].value
                      : ''
                  }
                  onChange={(e) =>
                    this.handleLocalChange(
                      e.target.value,
                      activeLang,
                      'contact.gps_coordinates',
                    )
                  }
                />
              </InputWrapper>
            </SectionWrapper>
          </ContentWrapper>
        </Wrapper>

        <ButtonRelativeWrapper>
          <Button onClick={() => this.saveInfo()} primary loading={saveLoading}>
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper>
      </React.Fragment>
    );
  }
}

AboutUsContact.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(AboutUsContact);
