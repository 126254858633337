import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, FileInput, Button, Label } from 'oxyrion-ui/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexBoxStart } from '../ReusableComponents';
import { __ } from '../../Utils';
import DateAndTimerPicker from '../DateAndTimePicker';
import SearchableSelect from '../SearchableSelect';
import { searchBrands } from '../../Utils/ApiSearchFunctions';

const Wrapper = styled.div`
  text-align: left;
  width: 90%;
  margin: auto;
`;

const CustomInput = styled(Input)`
  width: 98%;
  margin: ${rem(10)} 0;
  padding: 0;
`;

const CustomFileInput = styled(FileInput)`
  height: ${rem(36)};
`;

class ModifyPriceList extends Component {
  render() {
    const {
      nameValue,
      nameOnChange,
      brandValue,
      brandOnChange,
      submitMessage,
      onSubmit,
      fileValue,
      fileOnChange,
      dateValue,
      dateOnChange,
      removeFile,
      nameError,
      brandError,
      fileError,
      dateError,
      loading,
      editPriceListId,
    } = this.props;

    return (
      <Wrapper>
        <Label ondrop={(e) => alert(e)}>{__('Názov *')}</Label>
        <CustomInput
          disabled={editPriceListId}
          value={nameValue}
          error={nameError}
          onChange={(e) => nameOnChange(e.target.value)}
        />

        <Label ondrop={(e) => alert(e)}>{__('Značka *')}</Label>
        <SearchableSelect
          loadOptions={(query) => searchBrands(query, 'SK')}
          value={brandValue}
          error={brandError}
          placeholder={__('Značka')}
          handleOnChange={(e) => brandOnChange(e)}
        />
        {!editPriceListId && (
          <>
            <Label>{__('Súbor *')}</Label>
            <CustomFileInput
              error={fileError}
              item={fileValue}
              onChange={(e) => fileOnChange(e.target.files)}
              onRemoveFile={() => removeFile()}
            />
          </>
        )}
        <Label ondrop={(e) => alert(e)}>{__('Dátum platnosti od *')}</Label>
        <DateAndTimerPicker
          disabled={editPriceListId}
          value={dateValue}
          error={dateError}
          onChange={(e) => dateOnChange(e)}
        />
        <br />
        <FlexBoxStart>
          <Button primary loading={loading} onClick={() => onSubmit()}>
            {submitMessage}
          </Button>
        </FlexBoxStart>
        <br />
      </Wrapper>
    );
  }
}

ModifyPriceList.propTypes = {
  nameValue: PropTypes.string.isRequired,
  nameOnChange: PropTypes.string.isRequired,
  brandValue: PropTypes.object.isRequired,
  brandOnChange: PropTypes.func.isRequired,
  submitMessage: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fileValue: PropTypes.string.isRequired,
  fileOnChange: PropTypes.func.isRequired,
  dateValue: PropTypes.string.isRequired,
  dateOnChange: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  nameError: PropTypes.bool,
  brandError: PropTypes.bool,
  fileError: PropTypes.bool,
  dateError: PropTypes.bool,
  loading: PropTypes.bool,
};

ModifyPriceList.defaultProps = {
  nameError: false,
  brandError: false,
  fileError: false,
  dateError: false,
  loading: false,
};

export default ModifyPriceList;
