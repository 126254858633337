import React from 'react';
import BaseTable from '../BaseTable';
import tableHeaders from '../../ContentConfig/banners';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import ControlButton from '../../Components/ReusableComponents/ControlButton';
import { RouteLinkWraper as LinkWrap } from '../../Components/ReusableComponents';
import {
  __,
  renderLink,
  formatDate,
  FORMAT_HUMANE_DATETIME,
  getPageIdFromProps,
} from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import AdminAPI from '../../AdminAPI';

const PUBLISH_APPS_FILTER = ['Všetky', 'xFarby', 'Portal', 'Dejmark'];

class EshopBannersList extends BaseTable {
  constructor(props) {
    super({ ...props });
    this.headers = tableHeaders;
    this.segmentName = __('Bannery');
    this.accessors = [
      'name',
      'published_apps',
      'country',
      'type',
      'priority',
      'published_from',
      'published_to',
    ];
    this.setSorter('name');
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.state = {
      ...this.state,
      lastSearchValue: '',
      activeMessage: 'Všetky',
      activeCountry: 'Všetky',
      activePublishedApps: 'Všetky',
      showAlert: true,
    };

    this.pageId = getPageIdFromProps(this.props);
  }

  componentDidUpdate() {
    if (this.pageId !== getPageIdFromProps(this.props)) {
      this.pageId = getPageIdFromProps(this.props);
      this.init();
    }
  }

  async fetchData(parameters = {}) {
    const { lastSearchValue, activeCountry, sorter, activePublishedApps } =
      this.state;

    try {
      this.setState({
        loading: true,
      });
      const params = Object.assign({}, parameters, { sorter });
      if (!params.q && params.q !== '') {
        params.q = lastSearchValue;
      }
      if (activeCountry === 'Všetky') {
        params.country = [];
      } else if (activeCountry === 'SK') {
        params.country = ['SK'];
      } else if (activeCountry === 'CZ') {
        params.country = ['CZ'];
      } else if (activeCountry === 'SK, CZ') {
        params.country = ['SK', 'CZ'];
      } else if (activeCountry === 'EN') {
        params.country = ['EN'];
      } else if (activeCountry === 'AT') {
        params.country = ['AT'];
      }

      if (activePublishedApps === 'Všetky') {
        params.publishedApps = [];
      } else if (activePublishedApps === 'xFarby') {
        params.publishedApps = ['xFarby'];
      } else if (activePublishedApps === 'Portal') {
        params.publishedApps = ['Portal'];
      }

      const newData = await AdminAPI.getBannersAction(this.pageId, params);

      this.setState({
        content: this.normalizeColumns(newData.items),
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }
  normalizedHeaders() {
    const normalizedHeaders = this.headers.map((header) => ({
      value: header.name,
      clickable: header.clickable,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  handleSyncOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/banners/${this.pageId}/${id}`);
  }

  normalizeColumns(data) {
    const { firm } = this.props;
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || value === 0 ? value : placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const _formatCountries = (countries) => {
      return countries.join(', ');
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        name: _shapeData(
          renderLink(
            `../banners/${this.pageId}/${item._id}`,
            `${item.name || '-'}`,
            () => this.handleSyncOnClick(item._id),
          ),
        ),
        published_apps: _shapeData(_formatCountries(item.published_apps || [])),
        country: _shapeData(_formatCountries(item.country)),
        type: _shapeData(item.type),
        priority: _shapeData(
          (item.translations &&
            item.translations[firm.toUpperCase()] &&
            item.translations[firm.toUpperCase()].priority) ||
            0,
        ),
        published_from: _shapeData(
          formatDate(item.published_from, FORMAT_HUMANE_DATETIME),
        ),
        published_to: _shapeData(
          item.published_to &&
            formatDate(item.published_to, FORMAT_HUMANE_DATETIME),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }
  async loadSearchResult(value) {
    this.setState({ loading: true });
    const { loadResults } = this.state;
    if (loadResults[value]) {
      this.setState({
        content: this.normalizeColumns(loadResults[value].items),
        loading: false,
        limit: loadResults[value].limit,
        offset: loadResults[value].offset,
        total: loadResults[value].total,
        nextOffset: loadResults[value].next_offset,
        lastSearchValue: value.toLowerCase(),
      });
    } else {
      const newData = await this.fetchData(
        value !== undefined ? { q: value.toLowerCase() } : {},
      );
      loadResults[value] = newData;
      this.setState({ loadResults, lastSearchValue: value.toLowerCase() });
    }
  }
  renderControlBar() {
    const { history } = this.props;
    const { lastSearchValue, activeCountry, activePublishedApps } = this.state;
    return (
      <ControllBar
        history={history}
        name={`${__('Bannery')} ${this.pageId}`}
        defaultValue={lastSearchValue}
        onChange={(val) => this.loadSearchResult(val)}
      >
        <ControlButton name={activePublishedApps}>
          <LinkWrap>
            {PUBLISH_APPS_FILTER.map((label) => (
              <div
                onClick={(e) =>
                  this.filterPublishedAppsChange(e.target.innerHTML)
                }
              >
                {__(label)}
              </div>
            ))}
          </LinkWrap>
        </ControlButton>
        <ControlButton name={activeCountry}>
          <LinkWrap>
            <div onClick={(e) => this.handleCountryChange(e.target.innerHTML)}>
              {__('Všetky')}
            </div>
            <div onClick={(e) => this.handleCountryChange(e.target.innerHTML)}>
              {__('SK')}
            </div>
            <div onClick={(e) => this.handleCountryChange(e.target.innerHTML)}>
              {__('CZ')}
            </div>
            <div onClick={(e) => this.handleCountryChange(e.target.innerHTML)}>
              {__('SK, CZ')}
            </div>
            <div onClick={(e) => this.handleCountryChange(e.target.innerHTML)}>
              {__('EN')}
            </div>
            <div onClick={(e) => this.handleCountryChange(e.target.innerHTML)}>
              {__('AT')}
            </div>
          </LinkWrap>
        </ControlButton>
        <ControlBarButton
          primary
          small
          onClick={() => this.handleNewOnClick('new')}
          icon="plus"
        >
          {__('Vytvoriť nový')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  filterPublishedAppsChange(app) {
    this.setState(
      {
        activePublishedApps: __(app),
      },
      () => {
        this.fetchData();
      },
    );
  }

  handleCountryChange(activeCountry) {
    this.setState(
      {
        activeCountry: __(activeCountry),
      },
      () => {
        this.fetchData();
      },
    );
  }
  handleNewOnClick(id) {
    const { history, firm } = this.props;

    history.push(`/${firm}/banners/${this.pageId}/${id}`);
  }
  render() {
    return super.render();
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(EshopBannersList);
