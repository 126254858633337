import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import {
  Message,
  Select,
  Loader,
  Label,
  Icon,
  Tabs,
  ThemeAdmin,
} from 'oxyrion-ui/lib';
import SortableTree from 'react-sortable-tree';
import { connect } from '../../Store';
import { __, flatDeep } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import './style.css';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import CategoryEditor from '../../Components/CategoryEditor';
import { LANGS } from '../../ContentConfig/languagesArray';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  padding: ${rem(4)};
  margin-bottom: ${rem(100)};
`;

const Col = styled.div`
  width: ${rem(500)};
`;
export const ErrorText = styled(Label)`
  color: #e94141;
  margin-bottom: ${rem(4)};
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  height: ${rem(32)};
  margin-left: ${rem(4)};
  margin-top: ${rem(8)};
  font-weight: 700;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const SpaceBetweenRow = styled(Row)`
  justify-content: space-between;
`;

const InputWrapper = styled.div`
  margin-bottom: ${rem(2)};
  margin-right: ${rem(20)};
  min-width: ${rem(200)};
`;

const CategoryName = styled.div`
  position: relative;
`;

const EditButton = styled.div`
  width: ${rem(32)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const CategoryLabel = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${rem(210)};
`;

const TreePart = styled.div`
  width: 100%;
`;
const fileUploadURL = `${process.env.REACT_APP_ADMIN_DOMAIN}/admin/{chanelId}/categories/{categoryId}/image`;

const fileFavoriteUploadURL = `${process.env.REACT_APP_ADMIN_DOMAIN}/admin/{chanelId}/favorite-categories/{favoriteCategoryId}/image`;

class CategoriesSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      menuCategories: {
        saveLoading: false,
        selectedChannel: 'xFarby',
        selectedLang: 'SK',
        categoriesTree: [],
        openedCategories: [],
        categoryToEditId: '',
        newCategoriesTemp: {},
        treeData: [],
        allCategories: [],
        selectedCategory: null,
        dataTreeSaving: false,
        showConflictConfirmDialog: null,
        showDeleteDialog: false,
        urlError: false,
      },
      favoriteCategories: {
        saveLoading: false,
        selectedChannel: 'xFarby',
        selectedLang: 'SK',
        categoriesTree: [],
        openedCategories: [],
        categoryToEditId: '',
        newCategoriesTemp: {},
        treeData: [],
        allCategories: [],
        selectedCategory: null,
        dataTreeSaving: false,
        showConflictConfirmDialog: null,
        showDeleteDialog: false,
      },
      activeTab: 'tab1',
    };
  }

  async componentDidMount() {
    const { menuCategories, favoriteCategories } = this.state;
    await this.fetchData(
      menuCategories.selectedChannel,
      menuCategories.selectedLang,
    );
    await this.fetchFavoriteCategoriesData(
      favoriteCategories.selectedChannel,
      favoriteCategories.selectedLang,
    );
  }

  async fetchData(chanelId, lang) {
    const { treeData } = this.state.menuCategories;
    try {
      const categoriesTree = await AdminAPI.getCategoriesTreeAction({
        chanelId,
        lang,
      });

      const allCategoriesResult = await AdminAPI.getCategoriesAction(chanelId, {
        lang,
      });

      const data = categoriesTree.categories.find(
        (c) => c.chanel_id === chanelId,
      ).items;

      this.setState({
        loading: false,
        menuCategories: {
          categoriesTree: data,
          saveLoading: false,
          selectedCategory: null,
          selectedChannel: chanelId,
          selectedLang: lang,
          newCategoriesTemp: {},
          showDeleteDialog: false,
          deleteLoading: false,
          urlError: false,
          showConflictConfirmDialog: null,
          treeData: this.formatData(data, treeData),
          allCategories: allCategoriesResult
            ? allCategoriesResult.categories
            : [],
        },
      });
    } catch (e) {
      console.log('fetchData error: ', e);
      switch (e.response && e.response.status) {
        case 403:
          this.setState({
            loading: false,
            error: __('Na zobrazenie kategórií nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            loading: false,
            error: __('Kategórie sa nenašli'),
          });
          break;
        default:
          this.setState({
            loading: false,
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    }
  }

  formatData(tree, oldTree) {
    if (tree && tree.length > 0 && oldTree && oldTree.length > 0) {
      return tree.map((t, index) => ({
        name: t.name,
        _id: t._id,
        expanded: oldTree && oldTree[index] && oldTree[index].expanded,
        title: (
          <CategoryLabel>
            <CategoryName>{t.name}</CategoryName>
            <EditButton onClick={() => this.handleEditChange(t._id, t.name)}>
              <Icon name="edit" size="m" />
            </EditButton>
          </CategoryLabel>
        ),
        children:
          t.categories &&
          this.formatData(
            t.categories,
            oldTree && oldTree[index] && oldTree.categories,
          ),
      }));
    }

    return [];
  }

  async deleteCategory(id, forced) {
    const { menuCategories } = this.state;
    menuCategories.deleteLoading = true;
    this.setState({
      menuCategories,
    });

    try {
      await AdminAPI.deleteCategoryAction(id, menuCategories.selectedChannel, {
        forced,
      });

      this.fetchData(
        menuCategories.selectedChannel,
        menuCategories.selectedLang,
      );
    } catch (e) {
      switch (e.response && e.response.status) {
        case 409:
          menuCategories.showDeleteDialog = true;
          menuCategories.deleteLoading = false;
          this.setState({
            menuCategories,
          });
          break;
        default:
          this.setState({
            error: __('Podskupinu sa nepodarilo odstrániť'),
          });
          break;
      }
    }
  }

  async reloadCategory(id) {
    const { menuCategories } = this.state;
    await this.fetchData(
      menuCategories.selectedChannel,
      menuCategories.selectedLang,
    );
    const menuCategoriesNew = this.state.menuCategories;
    const selectedCategory = await AdminAPI.getCategoryAction(
      menuCategoriesNew.chanelId,
      id,
    );
    menuCategoriesNew.selectedCategory = selectedCategory;
    this.setState({
      menuCategories: menuCategoriesNew,
    });
  }

  handleChange(field, value) {
    const { menuCategories } = this.state;

    if (field === 'channel') {
      menuCategories.selectedChannel = value;
    } else if (field === 'lang') {
      menuCategories.selectedLang = value;
    }

    this.setState({
      menuCategories,
    });

    this.fetchData(menuCategories.selectedChannel, menuCategories.selectedLang);
  }

  handleEditChange(id) {
    const { menuCategories } = this.state;
    const category = menuCategories.allCategories.find((a) => a._id === id);
    menuCategories.selectedCategory = category;
    menuCategories.showConflictConfirmDialog = false;
    menuCategories.showDeleteDialog = false;
    menuCategories.urlError = false;
    if (category) {
      this.setState({
        menuCategories,
      });
    }
  }

  findAncestors(parent, array = []) {
    const { menuCategories } = this.state;

    if (!parent) {
      return array;
    }

    const newArray = [...array];
    newArray.push(parent);

    const c = menuCategories.allCategories.find((a) => a._id === parent);

    if (!c) {
      return array;
    }

    return this.findAncestors(c.parent, newArray);
  }

  async saveDataTree(treeData) {
    const { menuCategories } = this.state;
    const a = [...menuCategories.allCategories];
    const normalizeData = this.prepareTreeDataToSave(treeData, a, []);
    const dataToSave = flatDeep(normalizeData, Infinity).filter((category) => {
      const oldCategory = menuCategories.allCategories.find(
        (o) => o._id === category._id,
      );

      if (oldCategory && category) {
        if (oldCategory.order !== category.order) {
          return true;
        }

        if (
          JSON.stringify(oldCategory.ancestors) !==
          JSON.stringify(category.ancestors)
        ) {
          return true;
        }
      }

      return false;
    });

    const normalizedDataToSave = dataToSave.map((d) => ({
      _id: d._id,
      order: d.order,
      parent: d.parent,
      ancestors: d.ancestors,
    }));

    try {
      const params = {};
      params.lang = this.state.menuCategories.selectedLang;
      params.body = {
        categories: normalizedDataToSave,
      };

      if (normalizedDataToSave && normalizedDataToSave.length > 0) {
        menuCategories.dataTreeSaving = true;
        this.setState({
          menuCategories,
        });

        const result = await AdminAPI.putCategoriesStructureAction(
          this.state.menuCategories.selectedChannel,
          params,
        );

        // this.fetchData(this.state.selectedChannel, this.state.selectedLang);
        menuCategories.allCategories = result.categories || [];
        menuCategories.dataTreeSaving = false;
        this.setState({
          menuCategories,
        });
      }
    } catch (e) {
      menuCategories.dataTreeSaving = false;
      this.setState({
        error: __('Štruktúru sa nepodarilo uložiť'),
        menuCategories,
      });
    }
    menuCategories.allCategories = flatDeep(normalizeData, Infinity);
    this.setState({
      menuCategories,
      // treeData: this.formatData(normalizeData),
    });
  }

  prepareTreeDataToSave(tree, allCategories, ancestors = []) {
    return tree.map((t, index) => {
      const oldCategory = { ...allCategories.find((a) => a._id === t._id) };
      return [
        Object.assign({}, oldCategory, {
          order: index,
          ancestors,
          parent: ancestors[ancestors.length - 1],
        }),
      ].concat(
        t.children
          ? this.prepareTreeDataToSave(
              t.children,
              allCategories,
              ancestors.concat([t._id]),
            )
          : [],
      );
    });
  }

  async saveCategory(id, selectedCategory, forced = false) {
    const { menuCategories } = this.state;
    menuCategories.saveLoading = true;
    this.setState({
      menuCategories,
      error: false,
    });

    const oldCategory = menuCategories.allCategories.find((a) => a._id === id);

    try {
      const params = {
        forced,
      };
      params.body = {
        industry_category: selectedCategory.industry_category,
        name: selectedCategory.name,
        path: selectedCategory.path,
        heureka_id: selectedCategory.heureka_id,
        lang: menuCategories.selectedLang,
        description: selectedCategory.description,
        meta_title: selectedCategory.meta_title,
        meta_description: selectedCategory.meta_description,
        video_id:
          (selectedCategory.video && selectedCategory.video.value) || null,
      };

      if (
        selectedCategory.parent &&
        oldCategory.parent &&
        selectedCategory.parent !== oldCategory.parent
      ) {
        const newAncestors = this.findAncestors(selectedCategory.parent, []);
        params.body.ancestors = newAncestors;
        params.body.parent = selectedCategory.parent;
      }
      await AdminAPI.putCategoryAction(
        id,
        menuCategories.selectedChannel,
        params,
      );
      this.fetchData(
        menuCategories.selectedChannel,
        menuCategories.selectedLang,
      );
    } catch (e) {
      switch (e.response && e.response.status) {
        case 400:
          this.setState({
            menuCategories: {
              ...this.state.menuCategories,
              saveLoading: false,
            },
            error: __('Kategória nemá vyplnené potrebné atribúty'),
          });
          break;
        case 409:
          menuCategories.showConflictConfirmDialog = {
            value: true,
            type: 'put',
          };
          menuCategories.saveLoading = false;
          this.setState({
            menuCategories,
            // error: __('Kategória s touto url adresou už existuje'),
          });
          break;
        default:
          this.setState({
            menuCategories: {
              ...this.state.menuCategories,
              saveLoading: false,
            },
            error: __('Kategóriu sa nepodarilo uložiť'),
          });
          break;
      }
    }
  }

  async createCategory(category, forced = false) {
    const { menuCategories } = this.state;
    menuCategories.saveLoading = true;
    this.setState({
      menuCategories,
      error: false,
    });

    try {
      const params = {
        forced,
      };
      params.body = {
        industry_category: category.industry_category,
        name: category.name,
        lang: menuCategories.selectedLang,
        heureka_id: category.heureka_id,
        path: category.path,
        description: category.description,
        meta_title: category.meta_title,
        meta_description: category.meta_description,
      };

      if (category.parent) {
        params.body.parent = category.parent;
      }

      await AdminAPI.postCategoryAction(
        menuCategories.selectedChannel,
        menuCategories.selectedLang,
        params,
      );

      this.fetchData(
        menuCategories.selectedChannel,
        menuCategories.selectedLang,
      );
    } catch (e) {
      console.log(e);

      switch (e.response && e.response.status) {
        case 400:
          menuCategories.saveLoading = false;
          this.setState({
            menuCategories,
            error: __('Kategória nemá vyplnené potrebné atribúty'),
          });
          break;
        case 409:
          menuCategories.saveLoading = false;
          menuCategories.showConflictConfirmDialog = {
            value: true,
            type: 'post',
          };
          this.setState({
            menuCategories,
            // error: __('Kategória s touto url adresou už existuje'),
          });
          break;
        default:
          menuCategories.saveLoading = false;
          this.setState({
            menuCategories,
            error: __('Kategóriu sa nepodarilo uložiť'),
          });
          break;
      }
    }
  }

  handleCategoriesPositionsChange(tree) {
    const { menuCategories } = this.state;
    menuCategories.treeData = tree;
    this.setState(
      {
        menuCategories,
      },
      async () => {
        await this.saveDataTree(tree);
      },
    );
  }

  uploadCategoryImage = ({ file }) => {
    const headers = AdminAPI.appendAuthHeaders();

    const formData = new FormData();

    formData.append('file', file);

    return {
      url: fileUploadURL.replace(
        '{categoryId}',
        this.state.menuCategories.selectedCategory._id,
      ),
      headers: {
        Authorization: headers.get('Authorization'),
      },
      body: formData,
    };
  };
  async deleteCategoryImage(id, fullPath) {
    const { menuCategories } = this.state;
    try {
      await AdminAPI.deleteCategoryImageAction(
        menuCategories.selectedChannel,
        id,
        encodeURIComponent(fullPath),
      );
      await this.fetchData(
        menuCategories.selectedChannel,
        menuCategories.selectedLang,
      );
      const newCategories = this.state.menuCategories;
      newCategories.selectedCategory = newCategories.allCategories.find(
        (c) => c._id === id,
      );

      this.setState({
        menuCategories: newCategories,
        success: __('Obrázok sa podarilo úspešne odstrániť'),
      });
    } catch (e) {
      this.setState({
        error: __('Obrázok sa nepodarilo odstrániť'),
      });
    }
  }
  // FavoriteCategories

  async fetchFavoriteCategoriesData(chanelId, lang) {
    const { treeData } = this.state.favoriteCategories;
    try {
      const allCategoriesResult = await AdminAPI.getFavoriteCategoriesAction(
        chanelId,
        {
          lang,
        },
      );
      const categoriesTree = {
        categories: [],
      };
      const categories = {
        chanel_id: chanelId,
        items: allCategoriesResult.categories,
      };
      categoriesTree.categories.push(categories);
      const data = categoriesTree.categories.find(
        (c) => c.chanel_id === chanelId,
      ).items;

      // this.setState({
      //   favoriteCategories: {
      //     categoriesTree: data,
      //     saveLoading: false,
      //     selectedCategory: null,
      //     selectedChannel: chanelId,
      //     selectedLang: lang,
      //     newCategoriesTemp: {},
      //     deleteLoading: false,
      //     treeData: this.formatFavoriteCategoriesData(data, treeData),
      //     allCategories: allCategoriesResult
      //       ? allCategoriesResult.categories
      //       : [],
      //   },
      // });

      this.setState({
        loading: false,
        favoriteCategories: {
          categoriesTree: data,
          saveLoading: false,
          selectedCategory: null,
          selectedChannel: chanelId,
          selectedLang: lang,
          newCategoriesTemp: {},
          showDeleteDialog: false,
          deleteLoading: false,
          urlError: false,
          showConflictConfirmDialog: null,
          treeData: this.formatFavoriteCategoriesData(data, treeData),
          allCategories: allCategoriesResult
            ? allCategoriesResult.categories
            : [],
        },
      });
    } catch (e) {
      switch (e.response && e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie kategórií nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Kategórie sa nenašli'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  formatFavoriteCategoriesData(tree, oldTree) {
    if (tree && tree.length > 0 && oldTree && oldTree.length > 0) {
      return tree.map((t, index) => ({
        name: t.name,
        _id: t._id,
        expanded: oldTree && oldTree[index] && oldTree[index].expanded,
        title: (
          <CategoryLabel>
            <CategoryName>{t.name}</CategoryName>
            <EditButton
              onClick={() =>
                this.handleFavoriteCategoryEditChange(t._id, t.name)
              }
            >
              <Icon name="edit" size="m" />
            </EditButton>
          </CategoryLabel>
        ),
        children:
          t.categories &&
          this.formatFavoriteCategoriesData(
            t.categories,
            oldTree && oldTree[index] && oldTree.categories,
          ),
      }));
    }

    return [];
  }

  async deleteFavoriteCategory(id) {
    const { favoriteCategories } = this.state;
    favoriteCategories.deleteLoading = true;
    this.setState({
      favoriteCategories,
    });

    try {
      await AdminAPI.deleteFavoriteCategoryAction(
        id,
        favoriteCategories.selectedChannel,
      );
      this.fetchFavoriteCategoriesData(
        favoriteCategories.selectedChannel,
        favoriteCategories.selectedLang,
      );
    } catch (e) {
      switch (e.response && e.response.status) {
        case 409:
          favoriteCategories.deleteLoading = false;
          this.setState({
            favoriteCategories,
          });
          break;
        default:
          this.setState({
            error: __('Podskupinu sa nepodarilo odstrániť'),
          });
          break;
      }
    }
  }

  handleFavoriteCategoryChange(field, value) {
    const { favoriteCategories } = this.state;

    if (field === 'channel') {
      favoriteCategories.selectedChannel = value;
    } else if (field === 'lang') {
      favoriteCategories.selectedLang = value;
    }

    this.setState({
      favoriteCategories,
    });

    this.fetchFavoriteCategoriesData(
      favoriteCategories.selectedChannel,
      favoriteCategories.selectedLang,
    );
  }

  handleFavoriteCategoryEditChange(id) {
    const { favoriteCategories } = this.state;
    const category = favoriteCategories.allCategories.find((a) => a._id === id);
    favoriteCategories.selectedCategory = category;
    if (category) {
      this.setState({
        favoriteCategories,
      });
    }
  }

  async saveFavoriteCategory(id, selectedCategory) {
    const { favoriteCategories } = this.state;
    favoriteCategories.saveLoading = true;
    this.setState({
      favoriteCategories,
      error: false,
    });

    try {
      const params = {};
      params.body = {
        industry_category: selectedCategory.industry_category,
        name: selectedCategory.name,
        path: selectedCategory.path,
        description: selectedCategory.description,
        lang: favoriteCategories.selectedLang,
        meta_title: selectedCategory.meta_title,
        meta_description: selectedCategory.meta_description,
      };

      await AdminAPI.putFavoriteCategoryAction(
        id,
        favoriteCategories.selectedChannel,
        params,
      );
      this.fetchFavoriteCategoriesData(
        favoriteCategories.selectedChannel,
        favoriteCategories.selectedLang,
      );
    } catch (e) {
      switch (e.response && e.response.status) {
        case 400:
          this.setState({
            favoriteCategories: {
              ...this.state.favoriteCategories,
              saveLoading: false,
            },
            error: __('Kategória nemá vyplnené potrebné atribúty'),
          });
          break;
        case 409:
          favoriteCategories.saveLoading = false;
          break;
        default:
          this.setState({
            favoriteCategories: {
              ...this.state.favoriteCategories,
              saveLoading: false,
            },
            error: __('Kategóriu sa nepodarilo uložiť'),
          });
          break;
      }
    }
  }

  async createFavoriteCategory(category) {
    const { favoriteCategories } = this.state;
    favoriteCategories.saveLoading = true;
    this.setState({
      favoriteCategories,
      error: false,
    });

    try {
      const params = {};
      params.body = {
        industry_category: category.industry_category,
        name: category.name,
        lang: favoriteCategories.selectedLang,
        path: category.path,
        description: category.description,
        meta_title: category.meta_title,
        meta_description: category.meta_description,
      };

      await AdminAPI.postFavoriteCategoryAction(
        favoriteCategories.selectedChannel,
        favoriteCategories.selectedLang,
        params,
      );

      this.fetchFavoriteCategoriesData(
        favoriteCategories.selectedChannel,
        favoriteCategories.selectedLang,
      );
    } catch (e) {
      switch (e.response && e.response.status) {
        case 400:
          favoriteCategories.saveLoading = false;
          this.setState({
            favoriteCategories,
            error: __('Kategória nemá vyplnené potrebné atribúty'),
          });
          break;
        case 409:
          favoriteCategories.saveLoading = false;
          this.setState({
            favoriteCategories,
            // error: __('Kategória s touto url adresou už existuje'),
          });
          break;
        default:
          favoriteCategories.saveLoading = false;
          this.setState({
            favoriteCategories,
            error: __('Kategóriu sa nepodarilo uložiť'),
          });
          break;
      }
    }
  }
  async reloadFavoriteCategory(id) {
    const { favoriteCategories } = this.state;
    await this.fetchFavoriteCategoriesData(
      favoriteCategories.selectedChannel,
      favoriteCategories.selectedLang,
    );
    const favoriteCategoriesNew = this.state.favoriteCategories;
    const selectedCategory = await AdminAPI.getFavoriteCategoryAction(
      favoriteCategoriesNew.selectedChannel,
      id,
    );
    favoriteCategoriesNew.selectedCategory = selectedCategory;
    this.setState({
      favoriteCategories: favoriteCategoriesNew,
    });
  }
  uploadFavoriteCategoryImage = ({ file }) => {
    const headers = AdminAPI.appendAuthHeaders();

    const formData = new FormData();

    formData.append('file', file);

    return {
      url: fileFavoriteUploadURL.replace(
        '{favoriteCategoryId}',
        this.state.favoriteCategories.selectedCategory._id,
      ),
      headers: {
        Authorization: headers.get('Authorization'),
      },
      body: formData,
    };
  };
  async deleteFavoriteCategoryImage(id, fullPath) {
    const { favoriteCategories } = this.state;
    try {
      await AdminAPI.deleteFavoriteCategoryImageAction(
        favoriteCategories.selectedChannel,
        id,
        encodeURIComponent(fullPath),
      );

      await this.fetchFavoriteCategoriesData(
        favoriteCategories.selectedChannel,
        favoriteCategories.selectedLang,
      );
      const newCategories = this.state.favoriteCategories;
      newCategories.selectedCategory = newCategories.allCategories.find(
        (c) => c._id === id,
      );

      this.setState({
        favoriteCategories: newCategories,
        success: __('Obrázok sa podarilo úspešne odstrániť'),
      });
    } catch (e) {
      this.setState({
        error: __('Obrázok sa nepodarilo odtrániť'),
      });
    }
  }

  async checkUrl(url) {
    const { menuCategories } = this.state;
    menuCategories.saveLoading = true;
    menuCategories.urlError = false;
    this.setState({
      menuCategories,
    });

    try {
      const parameters = {
        chanelId: menuCategories.selectedChannel,
        lang: menuCategories.selectedLang,
      };

      const ye = await AdminAPI.checkCategoryUrlAction(url, parameters);

      console.log(ye);
      menuCategories.saveLoading = false;
      menuCategories.urlError = false;
      this.setState({
        menuCategories,
      });
    } catch (e) {
      menuCategories.saveLoading = false;
      menuCategories.urlError = true;
      this.setState({
        menuCategories,
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    return <ControllBar history={history} name={__('Kategórie produktov')} />;
  }

  render() {
    const {
      error,
      success,
      menuCategories,
      favoriteCategories,
      loading,
      activeTab,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        {this.renderControlBar()}
        <Tabs
          color={ThemeAdmin.color.primary}
          onChange={(to) => {
            this.setState({
              activeTab: to,
            });

            return true;
          }}
          activeTab={{
            id: activeTab,
          }}
        >
          <Tabs.Tab id="tab1" title={__('Kategórie pre menu')}>
            <Wrapper>
              <TreePart>
                <Row>
                  {/* <InputWrapper>
                <Label>{__('Aplikácia')}</Label>
                <Select
                  onChange={e => {
                    this.handleChange('channel', e.target.value);
                  }}
                >
                  {publicationCanals.map(i => (
                    <option key={i} value={i} selected={i === selectedChannel}>
                      {i}
                    </option>
                  ))}
                </Select>
              </InputWrapper> */}
                  <InputWrapper>
                    <Label>{__('Jazyk')}</Label>
                    <Select
                      onChange={(e) => {
                        this.handleChange('lang', e.target.value);
                      }}
                    >
                      {LANGS.map((i) => (
                        <option
                          key={i}
                          value={i}
                          selected={i === menuCategories.selectedLang}
                        >
                          {i}
                        </option>
                      ))}
                    </Select>
                  </InputWrapper>
                </Row>
                <Row>
                  <Col>
                    <SpaceBetweenRow>
                      <Title>{__('Kategórie:')}</Title>
                      {menuCategories.dataTreeSaving && <Loader size="l" />}
                    </SpaceBetweenRow>
                    <div style={{ height: 2000 }}>
                      <SortableTree
                        treeData={menuCategories.treeData}
                        onChange={(treeData) =>
                          this.handleCategoriesPositionsChange(treeData)
                        }
                        rowHeight={60}
                      />
                    </div>
                  </Col>
                </Row>
              </TreePart>
              <CategoryEditor
                key={JSON.stringify(menuCategories.selectedCategory)}
                onImageUploaded={(id) => this.reloadCategory(id)}
                category={menuCategories.selectedCategory}
                onSave={(categoryId, category, forced) =>
                  this.saveCategory(categoryId, category, forced)
                }
                onUpload={(file) => this.uploadCategoryImage(file)}
                onUrlCheck={(url) => this.checkUrl(url)}
                onDelete={(categoryId, forced) =>
                  this.deleteCategory(categoryId, forced)
                }
                onCreate={(category, forced) =>
                  this.createCategory(category, forced)
                }
                onImageDelete={(id, path) => this.deleteCategoryImage(id, path)}
                urlError={menuCategories.urlError}
                saveLoading={menuCategories.saveLoading}
                deleteLoading={menuCategories.deleteLoading}
                selectedChannel={menuCategories.selectedChannel}
                selectedLang={menuCategories.selectedLang}
                showConflictConfirmDialog={
                  menuCategories.showConflictConfirmDialog
                }
                showDeleteDialog={menuCategories.showDeleteDialog}
                allCategories={menuCategories.allCategories}
                categoryType="menu"
              />
            </Wrapper>
          </Tabs.Tab>
          <Tabs.Tab id="tab2" title={__('Obľúbené kategórie')}>
            <Wrapper>
              <TreePart>
                <Row>
                  {/* <InputWrapper>
                <Label>{__('Aplikácia')}</Label>
                <Select
                  onChange={e => {
                    this.handleChange('channel', e.target.value);
                  }}
                >
                  {publicationCanals.map(i => (
                    <option key={i} value={i} selected={i === selectedChannel}>
                      {i}
                    </option>
                  ))}
                </Select>
              </InputWrapper> */}
                  <InputWrapper>
                    <Label>{__('Jazyk')}</Label>
                    <Select
                      onChange={(e) => {
                        this.handleFavoriteCategoryChange(
                          'lang',
                          e.target.value,
                        );
                      }}
                    >
                      {LANGS.map((i) => (
                        <option
                          key={i}
                          value={i}
                          selected={i === favoriteCategories.selectedLang}
                        >
                          {i}
                        </option>
                      ))}
                    </Select>
                  </InputWrapper>
                </Row>
                <Row>
                  <Col>
                    <SpaceBetweenRow>
                      <Title>{__('Kategórie:')}</Title>
                      {favoriteCategories.dataTreeSaving && <Loader size="l" />}
                    </SpaceBetweenRow>
                    <div style={{ height: 2000 }}>
                      <SortableTree
                        treeData={favoriteCategories.treeData}
                        onChange={() => {}}
                        rowHeight={60}
                      />
                    </div>
                  </Col>
                </Row>
              </TreePart>
              <CategoryEditor
                key={JSON.stringify(favoriteCategories.selectedCategory)}
                onImageUploaded={(id) => this.reloadFavoriteCategory(id)}
                category={favoriteCategories.selectedCategory}
                onSave={(categoryId, category, forced) =>
                  this.saveFavoriteCategory(categoryId, category, forced)
                }
                onDelete={(categoryId) =>
                  this.deleteFavoriteCategory(categoryId, false)
                }
                onCreate={(category, forced) =>
                  this.createFavoriteCategory(category, forced)
                }
                onImageDelete={(id, path) =>
                  this.deleteFavoriteCategoryImage(id, path)
                }
                onUpload={(file) => this.uploadFavoriteCategoryImage(file)}
                urlError={favoriteCategories.urlError}
                saveLoading={favoriteCategories.saveLoading}
                deleteLoading={favoriteCategories.deleteLoading}
                selectedChannel={favoriteCategories.selectedChannel}
                selectedLang={favoriteCategories.selectedLang}
                allCategories={favoriteCategories.allCategories}
                categoryType="favorite"
              />
            </Wrapper>
          </Tabs.Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}

CategoriesSettings.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CategoriesSettings);
