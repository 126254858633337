import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoyaltyPrograms from '../../../Containers/LoyaltyPrograms';
import LoyaltyProgram from '../../../Containers/LoyaltyProgram';

function LoyaltyProgramCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={LoyaltyProgram} />
      <Route path={`${match.url}`} component={LoyaltyPrograms} />
    </Switch>
  );
}

LoyaltyProgramCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default LoyaltyProgramCard;
