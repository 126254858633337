import React from 'react';
import { Route } from 'react-router-dom';
import CampaignsCard from './Campaigns';
import MarketingListsCard from './MarketingLists';
import MarketingMaterials from './MarketingMaterials';
import GraphicCard from './GraphicCard';
import PrintsCard from './PrintsCard';
import VideosCard from './VideosCard';
import PhotosCard from './PhotosCard';
import MultiMediaCard from './MultimediaCard';
import InspirationsCard from './InspirationsCard';
import ReferencesCard from './ReferencesCard';
import InspirationsObjectsCard from './InspirationsObjectCard';
import LoyaltyProgramCard from './LoyaltyProgramCard';

function Marketing(props) {
  const { match } = props;
  return (
    <React.Fragment>
      <Route path={`${match.url}/campaigns`} component={CampaignsCard} />
      <Route path={`${match.url}/lists`} component={MarketingListsCard} />
      <Route path={`${match.url}/graphics`} component={GraphicCard} />
      <Route path={`${match.url}/prints`} component={PrintsCard} />
      <Route path={`${match.url}/videos`} component={VideosCard} />
      <Route path={`${match.url}/inspirations`} component={InspirationsCard} />
      <Route
        path={`${match.url}/inspirations-objects`}
        component={InspirationsObjectsCard}
      />
      <Route path={`${match.url}/references`} component={ReferencesCard} />
      <Route path={`${match.url}/photos`} component={PhotosCard} />
      <Route path={`${match.url}/multimedia`} component={MultiMediaCard} />
      <Route
        path={`${match.url}/marketing-materials`}
        component={MarketingMaterials}
      />
      <Route
        path={`${match.url}/loyalty-program`}
        component={LoyaltyProgramCard}
      />
    </React.Fragment>
  );
}

export default Marketing;
