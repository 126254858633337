import React, { Component } from 'react';
import Message from 'oxyrion-ui/lib/Message';
import baseTheme from 'oxyrion-ui/lib/Themes/baseTheme';
import Icon from 'oxyrion-ui/lib/Icon';
import { rem } from 'polished';
import { Tbody, Thead, Tr, Th, Td, Table } from 'oxyrion-ui/lib/Table';
import styled from 'styled-components';
import { __, getIdFromProps } from '../../Utils';
import {
  AnimatedFormMessageWrapper,
  DivIconWrapper,
  H2,
  InputWrapper,
  StyledInput,
  StyledLabel,
} from '../../Components/ReusableComponents';
import CheckAccess from '../../ACL/CheckAccess';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import AddNewLoyaltyProgramTier from '../../Components/AddNewLoyaltyProgramTier';
import AdminAPI from '../../AdminAPI';
import SettingsBox from '../../Components/SettingsBox';
import DateAndTimerPicker from '../../Components/DateAndTimePicker';
import { Button, Checkbox, Select } from 'oxyrion-ui/lib';
import moment from 'moment';

const Wrapper = styled.div`
  padding: 0 ${rem(15)};
  margin: ${rem(30)} 0;
  td,
  th {
    padding-left: 0;
  }
  tbody tr:last-of-type {
    border-bottom: none;
  }
`;

const TableWrapper = styled.div`
  margin: ${rem(10)};
`;

const CustomTh = styled(Th)`
  padding-right: ${rem(10)};
  :last-of-type {
    width: 100%;
  }
`;

const Row = styled.div`
  margin-bottom: ${rem(8)};
  display: flex;
`;

const CustomSelect = styled(Select)`
  width: 100%;
  margin: ${rem(0)} 0;
  padding: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  //padding: ${rem(24)} ${rem(0)} ${rem(24)} ${rem(24)};
`;

const FIRMS = ['SK', 'CZ'];
const REWARD_TYPES = [{ label: __('Produkt'), value: 'product' }];

class LoyaltyProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addNewTierLoading: false,
      errorMessage: '',
      loading: false,
      saveLoading: false,
      data: {
        name: '',
        firm: 'SK',
        rewards: [],
        reward_type: 'product',
        published: false,
        valid_from: '',
        valid_to: '',
      },
    };
  }

  componentWillMount() {
    if (getIdFromProps(this.props) !== 'new') {
      this.fetchData();
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  normalizeData(input) {
    return {
      ...input,
      valid_from: moment(input.valid_from),
      valid_to: moment(input.valid_to),
    };
  }

  async saveLoyaltyProgram() {
    const { data } = this.state;
    const { history, firm } = this.props;
    try {
      this.setState({
        saveLoading: true,
      });

      const id = getIdFromProps(this.props);

      if (id === 'new') {
        const body = {
          ...data,
        };
        const response = await AdminAPI.postLoyaltyProgramAction(firm, {
          body,
        });
        history.push(`/${firm}/loyalty-program/${response._id}`);
        this.setState({
          saveLoading: false,
        });
      } else {
        const body = {
          name: data.name ? data.name : undefined,
          valid_to: data.valid_to ? data.valid_to : undefined,
          published: data.published ? true : false,
        };

        const result = await AdminAPI.putLoyaltyProgramAction(firm, id, {
          body,
        });
        this.setState({
          saveLoading: false,
          data: this.normalizeData(result),
        });
      }
    } catch (e) {
      this.setState({
        saveLoading: false,
        errorMessage: __('Pri ukladaní dát sa vyskytla chyba'),
      });
    }
  }

  async fetchData(params = {}) {
    try {
      this.setState({
        loading: true,
      });
      const parameters = Object.assign({}, params, {});

      const result = await AdminAPI.getLoyaltyProgramAction(
        this.props.firm,
        getIdFromProps(this.props),
        parameters,
      );

      this.setState({
        loading: false,
        data: this.normalizeData(result),
        errorMessage: '',
      });
    } catch (e) {
      this.setState({
        loading: false,
        errorMessage: __('Nepodarilo sa načitať detail vernostného programu'),
      });
    }
  }

  async addTier(name, threshold_from, product) {
    const { data } = this.state;
    try {
      this.setState({
        addNewTierLoading: true,
        errorMessage: '',
      });

      const params = {
        tier_name: name,
        product_id: product.value,
        threshold_from: Number(threshold_from),
      };

      const body = {
        rewards: [...data.rewards].concat([params]),
      };

      const response = await AdminAPI.putLoyaltyProgramAction(
        this.props.firm,
        getIdFromProps(this.props),
        { body },
      );

      this.setState({
        addNewTierLoading: false,
        data: this.normalizeData(response),
      });
    } catch (e) {
      this.setState({
        errorMessage: __('Nepodarilo sa pridať úroveň'),
        addNewTierLoading: false,
      });
    }
  }

  async removeTier(indexRemove) {
    const { data } = this.state;
    try {
      const _data = { ...data };
      _data.rewards.splice(indexRemove, 1);

      const body = {
        rewards: _data.rewards,
      };

      const response = await AdminAPI.putLoyaltyProgramAction(
        this.props.firm,
        getIdFromProps(this.props),
        { body },
      );

      this.setState({
        data: this.normalizeData(response),
      });
    } catch (e) {
      this.setState({
        errorMessage: __('Nepodarilo sa vymazať úroveň'),
      });
    }
  }

  handleOnChange(field, value) {
    const { data } = this.state;
    data[field] = value;

    this.setState({ data });
  }

  render() {
    const { history } = this.props;
    const { data, addNewTierLoading, errorMessage, saveLoading } = this.state;

    const id = getIdFromProps(this.props);

    return (
      <React.Fragment>
        <ControllBar
          history={history}
          name={
            getIdFromProps(this.props) === 'new'
              ? __('Vytvorenie nového vernostného programu')
              : __('Detail vernostného programu')
          }
        />
        <AnimatedFormMessageWrapper display={errorMessage}>
          <Message error message={errorMessage} />
        </AnimatedFormMessageWrapper>
        <Wrapper>
          <Row>
            <SettingsBox addDisabled title={__('Základné informácie')}>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Názov')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(300) }}
                    value={data.name}
                    placeholder={__('Názov')}
                    onChange={(e) =>
                      this.handleOnChange('name', e.target.value)
                    }
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Typ odmeny')}</StyledLabel>
                  <CustomSelect
                    style={{ width: rem(320) }}
                    disabled={id !== 'new'}
                    onChange={(e) =>
                      this.handleOnChange('reward_type', e.target.value)
                    }
                  >
                    {REWARD_TYPES.map((r) => {
                      return (
                        <option
                          selected={r.value === data.reward_type}
                          value={r.value}
                        >
                          {r.label}
                        </option>
                      );
                    })}
                  </CustomSelect>
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Krajina')}</StyledLabel>
                  <CustomSelect
                    style={{ width: rem(320) }}
                    disabled={id !== 'new'}
                    onChange={(e) =>
                      this.handleOnChange('firm', e.target.value)
                    }
                  >
                    {FIRMS.map((f) => {
                      return (
                        <option selected={f === data.firm} value={f}>
                          {f}
                        </option>
                      );
                    })}
                  </CustomSelect>
                </InputWrapper>
              </Row>
            </SettingsBox>

            <SettingsBox addDisabled title={__('Nastavenie publikácie')}>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Platnosť od')}</StyledLabel>
                  <DateAndTimerPicker
                    value={data.valid_from}
                    onChange={(e) => this.handleOnChange('valid_from', e)}
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Platnosť do')}</StyledLabel>
                  <DateAndTimerPicker
                    value={data.valid_to}
                    onChange={(e) => this.handleOnChange('valid_to', e)}
                  />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Publikovať')}</StyledLabel>
                  <Checkbox
                    checked={data.published}
                    onChange={(e) =>
                      this.handleOnChange('published', e.target.checked)
                    }
                  />
                </InputWrapper>
              </Row>
            </SettingsBox>
          </Row>

          <ButtonWrapper>
            <Button
              primary
              loading={saveLoading}
              onClick={() => this.saveLoyaltyProgram()}
            >
              {getIdFromProps(this.props) === 'new'
                ? __('Uložiť')
                : __('Upraviť')}
            </Button>
          </ButtonWrapper>

          {getIdFromProps(this.props) !== 'new' && (
            <React.Fragment>
              <H2>{__('Úrovne')}</H2>
              <TableWrapper>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>{__('Názov úrovne')}</Th>
                      <Th>{__('Obrat od')}</Th>
                      <Th>{__('Produkt')}</Th>
                      <CustomTh />
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.rewards.map((item, index) => (
                      <Tr>
                        <Td>{item.tier_name}</Td>
                        <Td>{item.threshold_from}</Td>
                        <Td>{item.product_id}</Td>
                        <Td>
                          <DivIconWrapper
                            onClick={() => this.removeTier(index)}
                          >
                            <Icon name="close" color={baseTheme.color.danger} />
                          </DivIconWrapper>
                        </Td>
                      </Tr>
                    ))}

                    <AddNewLoyaltyProgramTier
                      onSubmit={(name, threshold_from, product) =>
                        this.addTier(name, threshold_from, product)
                      }
                      buttonLoading={addNewTierLoading}
                    />
                  </Tbody>
                </Table>
              </TableWrapper>
            </React.Fragment>
          )}
        </Wrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(LoyaltyProgram);
