import React, { Component } from 'react';
import Message from 'oxyrion-ui/lib/Message';
import styled from 'styled-components';
import { rem } from 'polished';
import baseTheme from 'oxyrion-ui/lib/Themes/baseTheme';

import { __ } from '../../Utils';
import PriceListForm from '../../Components/PriceListForm';

import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import API2 from '../../API2';
import CustomAPI from '../../CustomAPI';
import moment from 'moment';
import { searchBrands } from '../../Utils/ApiSearchFunctions';
import { Loader } from 'oxyrion-ui/lib';

const acceptedMimeTypes = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.addin.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/pdf',
];

const Wrapper = styled.div`
  box-sizing: border-box;
  height: ${rem(520)};
`;

class ModifyPriceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameValue: '',
      brandValue: null,
      // descriptionValue: '',
      submitMessage: __('Vytvoriť cenník'),
      errors: [],
      uploadLoading: false,
    };
  }

  componentDidMount() {
    window.addEventListener(
      'dragover',
      (e) => {
        e.preventDefault();
      },
      false,
    );
    window.addEventListener(
      'drop',
      (e) => {
        e.preventDefault();
      },
      false,
    );
  }

  componentWillReceiveProps(nextProps) {
    const { editPriceListId } = this.props;

    if (
      nextProps.editPriceListId &&
      editPriceListId !== nextProps.editPriceListId
    ) {
      this.fetchData(nextProps.editPriceListId);
    }
  }

  ondrop(e) {
    e.stopPropagation();
    e.preventDefault();
    this.onDragLeave();
    if (e.dataTransfer.files.length > 0) {
      const f = e.dataTransfer.files[0];
      if (!acceptedMimeTypes.includes(f.type)) {
        this.setState({ errors: [__('Zlý formát súboru.')] });
      } else {
        this.setState({
          file: f,
          fileValue: f.name,
          fileError: false,
          errors: [],
        });
      }
    }
  }

  ondrag() {
    this.dragWrapper.style.boxShadow = `inset 0 0 ${rem(10)} ${
      baseTheme.color.primary
    }`;
  }

  onDragLeave() {
    this.dragWrapper.style.boxShadow = `inset 0 0 ${rem(1)} transparent`;
  }
  nameOnChange(value) {
    if (value.length === 0) {
      this.setState({ nameValue: value, nameError: true });
    } else {
      this.setState({ nameValue: value, nameError: false });
    }
  }
  brandOnChange(value) {
    if (value) {
      this.setState({ brandValue: value, brandError: true });
    } else {
      this.setState({ brandValue: value, brandError: false });
    }
  }

  dateOnChange(value) {
    if (!value) {
      this.setState({ dateValue: value, dateError: true });
    } else {
      this.setState({ dateValue: value, dateError: false });
    }
  }

  descriptionOnChange(value) {
    this.setState({ descriptionValue: value });
  }

  fileOnChange(files) {
    if (files.length) {
      const f = files[0];

      if (!acceptedMimeTypes.includes(f.type)) {
        this.setState({ errors: [__('Zlý formát súboru.')] });
      } else {
        this.setState({
          fileValue: f.name,
          file: f,
          fileError: false,
          errors: [],
        });
      }
    }
  }

  removeFile() {
    this.setState({ fileValue: '', file: null, fileError: true });
  }

  async fetchData(priceListId) {
    const { firm } = this.props;

    try {
      this.setState({ fetchDataLoading: true });
      const r = await API2.getPricelistDetailAction(firm, priceListId);

      let brands = [];
      if (r.brandId) {
        brands = await searchBrands('', 'SK');
      }

      this.setState({
        nameValue: r.name,
        brandValue: brands.find((b) => b.value === r.brandId),
        dateValue: moment(r.dateFrom),
        fetchDataLoading: false,
      });
    } catch (e) {
      this.setState({
        errors: [__('Pri načítavaní dát sa vyskytla chyba')],
        fetchDataLoading: false,
      });
    }
  }

  async submit() {
    const { firm, onSubmit } = this.props;
    const { nameValue, brandValue, file, dateValue } = this.state;

    this.setState({ uploadLoading: true });
    try {
      if (this.props.editPriceListId) {
        const body = {
          brandId: brandValue.value ? brandValue.value : undefined,
        };

        await API2.putPricelistAction(firm, this.props.editPriceListId, {
          body,
        });
        onSubmit();
        this.setState({
          uploadLoading: false,
        });
      } else if (this.checkData()) {
        await CustomAPI.postPricelistAction(firm, {
          body: {
            file,
            name: nameValue,
            brandId: brandValue.value,
            dateFrom: dateValue,
          },
        });
        onSubmit();
        this.setState({
          nameValue: '',
          brandValue: '',
          dateValue: null,
          file: undefined,
          fileValue: '',
          uploadLoading: false,
        });
      }
    } catch (e) {
      console.log('e---', e);

      this.setState({
        errors: [__('Pri ukladaní dát sa vyskytla chyba')],
        uploadLoading: false,
      });
    }
  }

  checkData() {
    const { nameValue, brandValue, dateValue, file } = this.state;
    if (nameValue && brandValue && dateValue && file) {
      this.setState({ errors: [] });
      return true;
    }
    let errors = [];
    if (!nameValue) {
      this.setState({
        nameError: true,
      });
      errors = [...errors, __('Názov nie je zadaný.')];
    }
    if (!brandValue) {
      this.setState({
        brandError: true,
      });
      errors = [...errors, __('Značka nie je zadaná.')];
    }
    if (!file) {
      this.setState({
        fileError: true,
      });
      errors = [...errors, __('Súbor nie je vybraný.')];
    }
    if (!dateValue) {
      this.setState({
        dateError: true,
      });
      errors = [...errors, __('Dátum nie je vybraný.')];
    }
    this.setState({ errors, uploadLoading: false });
    return false;
  }

  render() {
    const {
      nameValue,
      brandValue,
      submitMessage,
      fileValue,
      dateValue,
      nameError,
      brandError,
      fileError,
      dateError,
      errors,
      uploadLoading,
      fetchDataLoading,
    } = this.state;

    if (fetchDataLoading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <Wrapper
        draggable
        innerRef={(ref) => {
          this.dragWrapper = ref;
        }}
        onDragOver={() => this.ondrag()}
        onDragLeave={() => this.onDragLeave()}
        onDrop={(e) => this.ondrop(e)}
      >
        <AnimatedFormMessageWrapper display={errors.length}>
          {errors.map((e) => (
            <Message key={e} error message={e} />
          ))}
        </AnimatedFormMessageWrapper>
        <PriceListForm
          editPriceListId={this.props.editPriceListId}
          nameValue={nameValue}
          nameOnChange={(value) => this.nameOnChange(value)}
          nameError={nameError}
          brandValue={brandValue}
          brandOnChange={(value) => this.brandOnChange(value)}
          brandError={brandError}
          fileValue={fileValue}
          fileOnChange={(files) => this.fileOnChange(files)}
          dateValue={dateValue}
          dateOnChange={(value) => this.dateOnChange(value)}
          dateError={dateError}
          removeFile={() => this.removeFile()}
          fileError={fileError}
          submitMessage={
            this.props.editPriceListId
              ? __('Upraviť cenník')
              : __('Vytvoriť cenník')
          }
          onSubmit={(value) => this.submit(value)}
          onDrop={(files) => this.onDrop(files)}
          loading={uploadLoading}
        />
      </Wrapper>
    );
  }
}

export default ModifyPriceList;
